import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  //eslint-disable-line
  courseDetail: {
    flexDirection: 'column',
    paddingTop: 4,
    paddingBottom: 4,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  flexRow: {
    flexDirection: 'row',
  },
  expandIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    transform: 'rotate(180deg)',
  },
  smallText: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: theme.palette.lightGray,
  },
  blue: {
    color: theme.palette.blue,
  },
  lightGray: {
    color: theme.palette.lightGray,
  },
  courseFullBtn: {
    backgroundColor: theme.palette.gray,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 12,
  },
  enrollBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.darkGreen,
    },
    fontWeight: 'bold',
    fontSize: 12,
  },
  accordionSummary: {
    cursor: 'default !important',
  },
  extraInfo: {
    overflowWrap: 'break-word',
  },
  greenText: { color: theme.palette.success.main },
  grayText: { color: theme.palette.lightGray },
  courseDetailsContainer: {
    paddingTop: 20,
  },
  semiBold: {
    fontSize: 18,
    fontWeight: 600,
  },
  regular: {
    fontSize: 14,
  },
  enrollBtnContainer: {
    textAlign: 'center',
    display: 'inherit',
  },
  mobileEnrollBtn: {
    width: '100%',
  },
  fillParent: {
    width: '100% !important',
  },
  courseFullIcon: {
    fontSize: '1.125rem',
  },
  flexGrowRow: {
    flexDirection: 'row',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-around',
  },
  alignRight: {
    marginLeft: 'auto',
    marginRight: 10,
  },
}))

export default useStyles
