import React from 'react'
import { makeStyles, Popover, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

/**
 * Component for showing the terms of use in a dialog
 */

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
    maxWidth: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

const DialogTitle = ({ children, onClose }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </>
  )
}

const TermsDialog = ({ extraInfo, open, handleClose, anchorEl }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const locale = i18next.language

  if (!extraInfo) {
    return null
  }

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.dialogContent}>
        <DialogTitle onClose={handleClose}>{t('terms')}</DialogTitle>
        <>
          <div
            className="extraInfo"
            dangerouslySetInnerHTML={{
              __html: extraInfo[locale],
            }}
          ></div>
        </>
      </div>
    </Popover>
  )
}

export default TermsDialog
