import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Card,
  Typography,
  CardContent,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Table,
} from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'

import priceService from '../../../services/prices'
import PriceTableRow from './PriceTableRow'
import utils from '../../../utils'
import useFirebase from '../../../hooks/firebase'

/**
 * Component for editing price types. This component is used in Admin/index
 */

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  card: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: 15,
    padding: 20,
  },
  header: {
    fontSize: 25,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  priceType: {
    fontSize: 14,
    fontWeight: 550,
    paddingBottom: theme.spacing(2),
  },
}))

const Prices = () => {
  const [prices, setPrices] = useState(null)
  const classes = useStyles()
  const methods = useForm()
  const firebase = useFirebase()

  useEffect(() => {
    const fetchPrices = async () => {
      const response = await priceService.getAll()
      setPrices(response)
    }
    fetchPrices()
  }, [])

  const onSubmit = async (data, event) => {
    event.preventDefault()
    const priceRange = data.priceRange //eslint-disable-line
    const morningPrice = Number(data.morning)
    const eveningPrice = Number(data.evening)

    const morningPrices = {
      ...prices.morning,
      [priceRange]: morningPrice,
    }
    const eveningPrices = {
      ...prices.evening,
      [priceRange]: eveningPrice,
    }
    try {
      const config = await firebase.getTokenConfig()
      const morningResponse = await priceService.update(
        'morning',
        morningPrices,
        config
      )
      const eveningResponse = await priceService.update(
        'evening',
        eveningPrices,
        config
      )

      setPrices({ morning: morningResponse, evening: eveningResponse })
    } catch (error) {
      console.log(error)
    }
  }

  if (!prices) return <p>loading prices</p>

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>Hinnat</Typography>
      <Card className={classes.card}>
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        <Typography className={classes.priceType}>
                          Aamu
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.priceType}>
                          Ilta
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(prices[Object.keys(prices)[0]])
                      .sort()
                      .map((priceRange) => (
                        <PriceTableRow
                          key={utils.generateRandomKey()}
                          label={utils.translatePriceLabel(priceRange)}
                          priceRange={priceRange}
                          prices={prices}
                          setPrices={setPrices}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </div>
  )
}

export default Prices
