export const setCourseTypes = (courseTypes) => {
  return {
    type: 'SET_COURSETYPES',
    payload: courseTypes,
  }
}

export const addCourseTypes = (courseTypes) => {
  return {
    type: 'ADD_COURSETYPES',
    payload: courseTypes,
  }
}

export const deleteCourseType = (courseType) => {
  return {
    type: 'DELETE_COURSETYPE',
    payload: courseType,
  }
}

export const updateCourseType = (courseType) => {
  return {
    type: 'UPDATE_COURSETYPE',
    payload: courseType,
  }
}

export const addCourses = (courses) => {
  return {
    type: 'ADD_COURSES',
    payload: courses,
  }
}

export const updateCourse = (course) => {
  return {
    type: 'UPDATE_COURSE',
    payload: course,
  }
}

export const deleteCourse = (course) => {
  return {
    type: 'DELETE_COURSE',
    payload: course,
  }
}

export const setCategory = (category) => {
  return {
    type: 'SET_CATEGORY',
    payload: category,
  }
}
