import React from 'react'
import { TextField, makeStyles, Typography } from '@material-ui/core'
import { Person } from '@material-ui/icons'

import TextWithIcon from '../../TextWithIcon'

/**
 * Field to change the max participants of the course
 */

const useStyles = makeStyles(() => ({
  textField: {
    width: 100,
    padding: 5,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 5,
  },
}))

const MaxParticipants = ({ course, archived, changeMaxParticipants }) => {
  const classes = useStyles()

  return (
    <div>
      <TextWithIcon
        icon={<Person />}
        text={
          <Typography display="inline" className={classes.title}>
            Osallistujien maksimimäärä
          </Typography>
        }
      />
      <TextField
        disabled={archived}
        className={classes.textField}
        defaultValue={course.maxParticipants}
        type="number"
        onChange={(event) => changeMaxParticipants(event)}
        InputProps={{
          className: classes.inputElement,
        }}
      />
    </div>
  )
}

export default MaxParticipants
