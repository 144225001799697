import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFirebase from './firebase'

const useAuth = () => {
  const [authUser, setAuthUser] = useState(null)
  const history = useHistory()
  const firebase = useFirebase()

  useEffect(() => {
    const unlisten = firebase.auth.onAuthStateChanged((user) => {
      user ? setAuthUser(user) : setAuthUser(null) //eslint-disable-line
    })
    return unlisten
  })

  const handleLogout = () => {
    firebase.signOut()
    if (history.location.pathname !== '/admin') {
      history.push('/admin')
    }
  }

  return [authUser, handleLogout]
}

export default useAuth
