import React from 'react'
import { Container, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    justifyContent: 'center',
    textAlign: 'center',
    height: 'auto',
    display: 'flex',
    overFlowY: 'hidden',
  },
  spinner: {
    color: '#2064AE',
  },
})

const Spinner = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <CircularProgress size={80} className={classes.spinner} />
    </Container>
  )
}

export default Spinner
