import React from 'react'
import { TableRow, TableCell, TextField, makeStyles } from '@material-ui/core'
import dateUtils from '../../../utils'

/**
 * Timetable row listed in Dates.js
 */

const useStyles = makeStyles((theme) => ({
  MuiTextField: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.white,
    padding: 5,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
}))

const TimeTableRow = ({
  setCourseDates,
  courseDates,
  date,
  setEdited,
  orderNumber,
  archived,
  extraClasses,
}) => {
  const classes = useStyles()

  const handleTimeChange = (e, d) => {
    const parts = e.target.value.split(':')
    const editedDate = new Date(d.getTime())
    editedDate.setHours(parts[0])
    editedDate.setMinutes(parts[1])
    setCourseDates(
      courseDates.map((dateObj) =>
        dateObj.toDateString() === editedDate.toDateString()
          ? editedDate
          : dateObj
      )
    )
    setEdited(true)
  }

  const handleTimeChangeForAll = (event) => {
    let parts = event.target.value.split(':')
    // In case control value is cleared use 00:00 instead
    if (!parts[0] || !parts[1]) {
      parts = ['00', '00']
    }
    setCourseDates(
      courseDates.map((d) => {
        d.setHours(parts[0])
        d.setMinutes(parts[1])
        return d
      })
    )
    setEdited(true)
  }

  const isChangeAllTimes = () => {
    return courseDates.every((d) => d.getHours() === 0 && d.getMinutes() === 0)
  }

  return (
    <TableRow key={date.getTime()}>
      <TableCell
        className={[extraClasses.tableCell, extraClasses.ordinalCell].join(' ')}
      >
        {orderNumber}.
      </TableCell>
      <TableCell className={extraClasses.tableCell}>
        {date.toLocaleDateString('fi-FI')}
      </TableCell>
      <TableCell className={extraClasses.tableCell}>
        <TextField
          disabled={archived}
          className={classes.MuiTextField}
          type="time"
          defaultValue={dateUtils.parseTime(date)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            className: classes.inputElement,
          }}
          onBlur={(event) => {
            // eslint-disable-next-line
            isChangeAllTimes()
              ? handleTimeChangeForAll(event)
              : handleTimeChange(event, date)
          }}
        />
      </TableCell>
    </TableRow>
  )
}

export default TimeTableRow
