import React, { useState } from 'react'
import { Container } from '@material-ui/core'

import NavBar from '../NavBar'
import SearchCourses from './SearchCourses'
import ExportData from './ExportData'
import Course from '../Course'
import Spinner from '../../Spinner'
import useAuth from '../../../hooks/auth'

const Archive = () => {
  const [courses, setCourses] = useState(null)
  const [loading, setLoading] = useState(false)
  const [user, logout] = useAuth()

  const sortedCourses = courses
    ? courses.sort(
        (a, b) =>
          new Date(a.dateTimes[0].start) - new Date(b.dateTimes[0].start)
      )
    : undefined

  return (
    <div>
      <NavBar user={user} logout={logout} />
      <Container maxWidth="lg">
        <SearchCourses setCourses={setCourses} setLoading={setLoading} />
        <ExportData courses={sortedCourses} />
        {sortedCourses
          ? sortedCourses.map((course) => (
              <Course key={course.id} course={course} archived />
            ))
          : null}
        {loading ? <Spinner /> : null}
      </Container>
    </div>
  )
}

export default Archive
