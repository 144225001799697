import React, { useEffect, useState } from 'react'
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import ScheduleIcon from '@material-ui/icons/Schedule'
import MultipleDatesPicker from '@randex/material-ui-multiple-dates-picker'
import DatePickerIcon from '@material-ui/icons/EventNote'

import dateUtils from '../../../utils'
import TimeTableRow from './TimeTableRow'
import TextWithIcon from '../../TextWithIcon'

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.white,
    padding: 5,
    width: 70,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
  tableCell: {
    verticalAlign: 'bottom',
  },
  MuiButton: {
    margin: 10,
    marginBottom: 0,
    width: 220,
    height: 40,
    fontWeight: 600,
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 20px',
    width: 400,
  },
  negativeMarginLeft: {
    marginLeft: '-15px',
  },
}))

/**
 * Course timetable inside EditCourse
 */

const Dates = ({
  course,
  setEditedCourse,
  setEdited,
  archived,
  tableRowClasses,
}) => {
  const [open, setOpen] = useState(false)
  const [duration, setDuration] = useState(
    course.dateTimes.length > 0
      ? dateUtils.parseDurationInMinutes(course.dateTimes[0])
      : 60
  )
  const [courseDates, setCourseDates] = useState(
    course.dateTimes.length > 0
      ? course.dateTimes.map((date) => new Date(date.start))
      : []
  )
  const classes = useStyles()

  useEffect(() => {
    setEditedCourse({
      ...course,
      dateTimes: dateUtils.parseDatesToArray(courseDates, duration),
    })
  }, [courseDates, duration]) // eslint-disable-line

  const handleDateChange = (selectedDates) => {
    setCourseDates(selectedDates)
    setOpen(false)
    setEdited(true)
  }

  const handleDurationChange = (event) => {
    setDuration(Number(event.target.value))
    setEdited(true)
  }

  if (course.dateTimes.length === 0) {
    return (
      <div>
        <Button
          disabled={archived}
          startIcon={<DatePickerIcon />}
          onClick={() => setOpen(!open)}
          className={classes.MuiButton}
        >
          Valitse päivät
        </Button>
        <MultipleDatesPicker
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={(selectedDates) => handleDateChange(selectedDates)}
          submitButtonText="Valitse"
          cancelButtonText="Peruuta"
          selectedDatesTitle="Valitut päivät:"
        />
      </div>
    )
  }

  return (
    <div>
      <MultipleDatesPicker
        open={open}
        selectedDates={courseDates}
        onCancel={() => setOpen(false)}
        onSubmit={(selectedDates) => handleDateChange(selectedDates)}
        submitButtonText="Valitse"
        cancelButtonText="Peruuta"
        selectedDatesTitle="Valitut päivät:"
      />
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingTop: 12 }} colSpan={2}>
                <Button
                  variant="contained"
                  className={classes.MuiButton}
                  startIcon={<DatePickerIcon />}
                  onClick={() => setOpen(!open)}
                  disabled={archived}
                >
                  Valitse päivät
                </Button>
              </TableCell>
              <TableCell>
                <Typography className={classes.title}>Kesto (min)</Typography>
                <TextField
                  disabled={archived}
                  className={classes.inputField}
                  type="number"
                  defaultValue={duration}
                  onChange={(event) => handleDurationChange(event)}
                  InputProps={{
                    className: classes.inputElement,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell}>
                <TextWithIcon
                  className={classes.negativeMarginLeft}
                  icon={<EventIcon />}
                  text={
                    <Typography display="inline" className={classes.title}>
                      Päivä
                    </Typography>
                  }
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <TextWithIcon
                  className={classes.negativeMarginLeft}
                  icon={<ScheduleIcon />}
                  text={
                    <Typography display="inline" className={classes.title}>
                      Alkamisaika
                    </Typography>
                  }
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseDates.map((date, index) => (
              <TimeTableRow
                archived={archived}
                key={dateUtils.generateRandomKey()}
                date={date}
                duration={duration}
                setEdited={setEdited}
                courseDates={courseDates}
                setCourseDates={setCourseDates}
                orderNumber={index + 1}
                extraClasses={tableRowClasses}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Dates
