import React from 'react'
import { Typography, TextField, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import useFirebase from '../../hooks/firebase'

/**
 * Login form for admin login
 */

const LoginForm = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm()

  const firebase = useFirebase()

  const handleLogin = async (data, event) => {
    event.preventDefault()

    const { email, password } = data

    try {
      await firebase.signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.log(error)
      setError('email', {
        type: 'manual',
        message: t('invalid_credentials'),
      })
    }
  }

  const formContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
  }

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    maxWidth: 500,
  }

  return (
    <div style={formContainerStyle}>
      <Typography variant="h5">{t('login')}</Typography>
      <form style={formStyle} onSubmit={handleSubmit(handleLogin)} noValidate>
        <TextField
          margin="normal"
          {...register('email', {
            required: 'Pakollinen',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Sähköpostiosoite ei kelpaa',
            },
          })}
          variant="outlined"
          label={t('form_email')}
          error={Boolean(errors.email)}
          helperText={errors.email && errors.email.message}
        />
        <TextField
          {...register('password', { required: 'Pakollinen' })}
          margin="normal"
          variant="outlined"
          type="password"
          label={t('form_password')}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
        />
        <Button variant="contained" color="primary" type="submit">
          {t('login')}
        </Button>
      </form>
    </div>
  )
}

export default LoginForm
