import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  makeStyles,
  useMediaQuery,
  MenuItem,
  Menu,
  ListItemText,
  ListItemIcon,
  Avatar,
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  Archive as ArchiveIcon,
  ExitToApp as LogoutIcon,
  SportsTennis as TennisIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import logo from '../../assets/Logo_white.png'

const useStyles = makeStyles((theme) => ({
  appBar: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  logoutBtn: {
    marginBottom: '10px',
    width: 170,
    height: 40,
    fontWeight: 600,
    backgroundColor: theme.palette.navyBlue.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.navyBlue.dark,
      color: theme.palette.white,
    },
  },
  menuIcon: {
    color: theme.palette.white,
    backgroundColor: '#0c183f45',
  },
  menuItem: {
    backgroundColor: theme.palette.white,
  },
  logo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: 'transparent',
  },
  logoBtn: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  adminText: {
    paddingRight: '35px',
    paddingTop: '5px',
    fontSize: 25,
    fontWeight: 'bold',
  },
}))

const Logo = () => {
  const history = useHistory()
  const classes = useStyles()
  return (
    <IconButton className={classes.logo} onClick={() => history.push('/')}>
      <Avatar className={classes.logoBtn} src={logo} alt="logo" />
    </IconButton>
  )
}

const NavBar = ({ user, logout }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const alignRight = { marginLeft: 'auto', marginRight: 10 }
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const linkStyle = {
    color: '#fafafa',
    marginLeft: 20,
    marginRight: 20,
    textDecoration: 'none',
  }

  const selectedLinkStyle = { ...linkStyle, color: '#bfd56d' }

  const { search, pathname } = useLocation()
  const queryParams = new URLSearchParams(search)
  let page = 'default'
  if (pathname === '/admin/archive') {
    page = 'archive'
  } else if (queryParams.size > 0 && queryParams.has('category')) {
    page = queryParams.get('category')
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    setOpen(false)
    logout()
  }

  if (!user) {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Logo />
          <Typography
            className={classes.adminText}
            style={alignRight}
            align="right"
          >
            ADMIN
          </Typography>
        </Toolbar>
      </AppBar>
    )
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Logo />
        {!isSmallScreen ? (
          <>
            <Typography style={{ marginLeft: 20, paddingRight: '15px' }}>
              {t('welcome')}
              {', '}
              {user.displayName ? user.displayName : 'admin'}
            </Typography>
            <Link
              style={page === 'default' ? selectedLinkStyle : linkStyle}
              to="/admin?category=default"
            >
              <Typography className={classes.title}>Kurssit</Typography>
            </Link>
            <Link
              style={page === 'weekend' ? selectedLinkStyle : linkStyle}
              to="/admin?category=weekend"
            >
              <Typography className={classes.title}>
                Viikonloppukurssit
              </Typography>
            </Link>
            <Link
              style={page === 'archive' ? selectedLinkStyle : linkStyle}
              to="/admin/archive"
            >
              <Typography className={classes.title}>Kurssiarkisto</Typography>
            </Link>
            <div style={alignRight}>
              <Typography align="right" className={classes.adminText}>
                ADMIN
              </Typography>
              <Button
                className={classes.logoutBtn}
                variant="contained"
                onClick={handleLogout}
              >
                {t('logout')}
              </Button>
            </div>
          </>
        ) : (
          <div style={alignRight}>
            <Typography align="right" className={classes.adminText}>
              ADMIN
            </Typography>
            <IconButton
              className={classes.menuIcon}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to="/admin?category=default"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <TennisIcon />
                </ListItemIcon>
                <ListItemText primary="Kurssit" />
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to="/admin?category=weekend"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <TennisIcon />
                </ListItemIcon>
                <ListItemText primary="Viikonloppukurssit" />
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to="/admin/archive"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText primary="Kurssiarkisto" />
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Kirjaudu ulos" />
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
