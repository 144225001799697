import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, makeStyles, Box } from '@material-ui/core'
import CourseTypeCard from './CourseTypeCard'
import Spinner from '../Spinner'

import LanguageSelect from './LanguageSelect'
import { StateContext } from '../../state'
import utils from '../../utils'
import useTimeout from '../../hooks/timeout'

/**
 * All course types at project root path "/" inside App
 */

const useStyles = makeStyles(() => ({
  container: { margin: '1em', textAlign: 'center' },
  header: {
    overflowWrap: 'break-word',
    fontWeight: 'bold',
  },
}))

const CourseTypes = () => {
  const { state } = useContext(StateContext)
  const { t } = useTranslation()
  const classes = useStyles()
  const [showSpinner, setShowSpinner] = useState(true)

  useTimeout(() => {
    setShowSpinner(false)
  }, 5000)

  if (utils.isEmpty(state.courseTypes) && showSpinner) return <Spinner />

  const courseTypes = Object.values(state.courseTypes).filter(
    (courseType) => courseType.category === state.category
  )

  return (
    <Box className={classes.container}>
      <LanguageSelect />
      <Typography className={classes.header} color="textPrimary" variant="h2">
        {t('coursetypes_title')}
      </Typography>
      <Typography color="textPrimary">{t('coursetypes_subtitle')}</Typography>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {courseTypes.length ? (
          courseTypes.map((courseType, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <CourseTypeCard courseType={courseType} />
            </Grid>
          ))
        ) : (
          <Typography style={{ margin: 20 }}>{t('no_courses')}</Typography>
        )}
      </Grid>
    </Box>
  )
}

export default CourseTypes
