import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import { BrowserRouter as Router } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/en-gb'
import App from './App'
import { StateProvider } from './state'
import { FirebaseProvider } from './providers/FirebaseProvider'

moment.locale('fi')

ReactDOM.render(
  <StateProvider>
    <FirebaseProvider>
      <Router>
        <App />
      </Router>
    </FirebaseProvider>
  </StateProvider>,
  document.getElementById('root')
)
