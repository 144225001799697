import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'

// eslint-disable-next-line
let adminTheme = createTheme({
  palette: {
    primary: {
      main: '#1E63AE',
      contrastText: '#FCFCFC',
    },
    secondary: {
      main: '#F42014',
      contrastText: '#FCFCFC',
    },
    success: {
      main: '#4CD137',
    },
    text: {
      primary: '#474747',
    },
    white: '#FCFCFC',
    blue: '#1E63AE',
    navyBlue: {
      main: '#06275D',
      dark: '#000033',
    },
    whiteBox: '#F8F8F8',
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'],
  },
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: '#1E63AE',
        color: '#FCFCFC',
        '&:before': {
          display: 'none',
        },
        margin: 15,
        '&$expanded': {
          margin: 15,
        },
      },
      rounded: {
        borderRadius: 12,
        '&:last-child': {
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: 'transparent',
      },
      expandIcon: {
        backgroundColor: 'transparent',
      },
    },
    MuiButtonBase: {
      root: {
        backgroundColor: '#EFEFEF',
      },
    },
    MuiTableCell: {
      root: {
        color: 'inherit',
        padding: '0px 8px',
        borderBottom: 'none',
      },
      body: {
        color: 'inherit',
      },
      head: {
        color: 'inherit',
      },
    },
    MuiInputBase: {
      root: {
        color: 'inherit',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'inherit',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none !important',
        },
        '&:after': {
          borderBottom: 'none !important',
        },
        '&:hover:before': {
          borderBottom: 'none !important',
        },
      },
      root: {
        backgroundColor: '#FCFCFC',
        color: '#474747',
      },
    },
  },
})

adminTheme = responsiveFontSizes(adminTheme)

export default adminTheme
