import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/coursetypes`

const getAll = async (category) => {
  const response = await axios.get(
    baseUrl + (category ? `?category=${category}` : '')
  )
  return response.data
}

const create = async (courseType, config) => {
  const response = await axios.post(baseUrl, courseType, config)
  return response
}

const findById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const remove = async (id, config) => {
  const response = await axios.delete(`${baseUrl}/${id}`, config)
  return response
}

const update = async (id, updatedCourseType, config) => {
  const response = await axios.put(
    `${baseUrl}/${id}`,
    updatedCourseType,
    config
  )
  return response
}

export default { getAll, create, findById, remove, update }
