import React, { useContext } from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'

import LoginForm from './LoginForm'
import NavBar from './NavBar'
import CourseType from './CourseType'
import AddCourseType from './AddCourseType'
import Prices from './Prices'

import { StateContext } from '../../state'
import useAuth from '../../hooks/auth'

/**
 * Admin page route inside App
 */

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
}))

const CourseTypeList = () => {
  const { state } = useContext(StateContext)

  return Object.values(state.courseTypes)
    .filter((courseType) => courseType.category === state.category)
    .map((courseType, index) => (
      <CourseType key={courseType.id} courseType={courseType} index={index} />
    ))
}

const Admin = () => {
  const [user, logout] = useAuth()
  const classes = useStyles()

  if (user === null) {
    return (
      <div>
        <NavBar logout={logout} user={user} />
        <LoginForm />
      </div>
    )
  }
  return (
    <div>
      <NavBar logout={logout} user={user} />
      <Container className={classes.container} maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <CourseTypeList />
            <AddCourseType />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Prices />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Admin
