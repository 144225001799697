import React, { useState, useEffect, useContext } from 'react'
import { Button, Grid, makeStyles, Divider } from '@material-ui/core'

import courseService from '../../../services/courses'
import { StateContext } from '../../../state'
import { deleteCourse, updateCourse } from '../../../state/actions'
import useFirebase from '../../../hooks/firebase'

import Dates from './Dates'
import Prices from './Prices'
import Participants from './Participants'
import MaxParticipants from './MaxParticipants'
import ExtraInfo from './ExtraInfo'

/**
 * Editable course data inside Course Accordion
 */

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
  
  },
  rightColumn: {
    paddingLeft: '11% !important',
    paddingTop: "30px !important",
    
  },
  saveButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
  },
  MuiButton: {
    margin: 10,
    width: 220,
    height: 40,
    fontWeight: 600,
  },
  verticalPadding: {
    padding: '5px 0',
  },
  textField: {
    width: 100,
    padding: 5,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: theme.palette.white,
  },
  participantContainer: {
    paddingRight: '0px !important',
  },
}))

const useDateTableRowStyles = makeStyles((theme) => ({
  tableCell: {
    border: `solid ${theme.palette.white} 2px`,
    borderLeft: 'none',
    height: 10,
    width: 150,
    fontSize: 14,
    fontWeight: 500,
  },
  ordinalCell: {
    width: 40,
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
}))

const EditCourse = ({ course, expanded, setExpanded, archived }) => {
  const classes = useStyles()
  const dateTableRowClasses = useDateTableRowStyles()
  const { dispatch } = useContext(StateContext)
  const firebase = useFirebase()

  const [editedCourse, setEditedCourse] = useState(course)
  const [edited, setEdited] = useState(false)
  const [participants, setParticipants] = useState([])

  const [prices, setPrices] = useState(course.prices)

  // Get course participants from backend if they aren't in state already
  useEffect(() => {
    const fetchParticipants = async () => {
      const config = await firebase.getTokenConfig()
      const response = await courseService.getParticipants(course.id, config)
      setParticipants(response)
    }
    if (expanded && course.participants > 0 && participants.length === 0) {
      fetchParticipants()
    }
  }, [course.id, course.participants, participants.length, expanded]) // eslint-disable-line

  useEffect(() => {
    if (edited) {
      setEditedCourse({ ...editedCourse, prices })
    }
  }, [prices]) //eslint-disable-line

  const handlePriceChange = (label, value) => {
    setEdited(true)
    setPrices({ ...prices, [label]: Number(value) })
  }

  const handleSave = async () => {
    if (edited) {
      try {
        const config = await firebase.getTokenConfig()
        const response = await courseService.updateCourse(
          course.id,
          editedCourse,
          config
        )
        if (response.status === 200) {
          dispatch(updateCourse(response.data))
        }
      } catch (error) {
        console.log(error)
      }
    }
    setExpanded(false)
  }

  const handleDelete = async () => {
    try {
      // eslint-disable-next-line
      if (window.confirm('Haluatko varmasti poistaa kurssin?')) {
        const config = await firebase.getTokenConfig()
        const response = await courseService.deleteCourse(course.id, config)
        if (response.status === 200) {
          dispatch(deleteCourse(course))
        }
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const handleExtraInfoChange = (locale, value) => {
    if (value === '') setEditedCourse({ ...editedCourse, extraInfo: null })
    setEditedCourse({
      ...editedCourse,
      extraInfo: { ...editedCourse.extraInfo, [locale]: value },
    })
    setEdited(true)
  }

  const changeMaxParticipants = (event) => {
    setEditedCourse({
      ...editedCourse,
      maxParticipants: Number(event.target.value),
    })
    setEdited(true)
  }

  const datesProps = {
    course: editedCourse,
    setEditedCourse,
    edited,
    setEdited,
    archived,
    tableRowClasses: dateTableRowClasses,
  }

  return (
    <Grid wrap="wrap" container spacing={3} className={classes.root}>
      <Grid item xs={6}>
        <Dates {...datesProps} />
      </Grid>
      <Grid item xs={6} className={classes.rightColumn}>
        <MaxParticipants
          course={course}
          archived={archived}
          changeMaxParticipants={changeMaxParticipants}
        />
        <Prices
          prices={prices}
          archived={archived}
          handlePriceChange={handlePriceChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12} className={classes.participantContainer}>
        <Participants
          course={course}
          participants={participants}
          setParticipants={setParticipants}
          archived={archived}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <ExtraInfo
          course={course}
          archived={archived}
          handleExtraInfoChange={handleExtraInfoChange}
        />
      </Grid>
      <Grid item xs className={classes.btnContainer}>
        <Button
          disabled={archived}
          className={classes.MuiButton}
          onClick={handleDelete}
          color="secondary"
          variant="contained"
        >
          Poista kurssi
        </Button>
        <Button
          disabled={archived}
          onClick={handleSave}
          className={[classes.MuiButton, classes.saveButton].join(' ')}
          variant="contained"
        >
          Tallenna muutokset
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditCourse
