import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Typography, Box, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import i18next from 'i18next'
import Course from './Course'
import courseService from '../../services/courses'
import Spinner from '../Spinner'
import moment from 'moment'

import { StateContext } from '../../state'
import { addCourses } from '../../state/actions'
import utils from '../../utils'
import './ExtraInfo.css'

/**
 * Route for Courses inside App
 * Lists all courses for single course type
 */

const useStyles = makeStyles((theme) => ({
  container: { margin: '1em', wordBreak: 'break-word' },
  monthSubHeading: {
    color: theme.palette.blue,
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: theme.spacing(9),
  },
  courseTypeTitle: {
    fontWeight: 'bold',
    marginTop: 35,
    marginBottom: 10,
  },
  bodyText: {
    fontSize: 14,
  },
  backBtn: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  extraInfo: {
    color: theme.palette.blue,
  },
}))

/**
 * Reduces array of courses into a dictionary with localized course months as keys
 * @param courses array of courses derived from app state
 * @returns dictionary with names of months as key and array of courses as values
 */
const arrangeByMonth = (courses) => {
  if (courses.length > 0) {
    const coursesByMonths = courses.reduce((accumulator, course) => {
      const firstDate = moment(course.dateTimes[0].start)
      const lastDate = moment(
        course.dateTimes[course.dateTimes.length - 1].start
      )

      const startingMonthNr = firstDate.month()
      const endingMonthNr = lastDate.month()

      if (startingMonthNr < endingMonthNr) {
        if (firstDate.date() >= 25) {
          firstDate.month(startingMonthNr + 1)
        }
      }

      // Returns month of date obj as a string
      let startingMonth = firstDate.format('MMMM')
      let startingYear = firstDate.format('YYYY')

      const startingMonthAndYear =
        startingMonth[0].toUpperCase() +
        startingMonth.substring(1) +
        ' ' +
        startingYear

      if (accumulator[startingMonthAndYear]) {
        return {
          ...accumulator,
          [startingMonthAndYear]:
            accumulator[startingMonthAndYear].concat(course),
        }
      }

      return { ...accumulator, [startingMonthAndYear]: [course] }
    }, {})

    return coursesByMonths
  }
  return null
}

const CourseList = () => {
  const { state, dispatch } = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()
  const { id } = useParams()
  const classes = useStyles()
  const locale = i18next.language

  useEffect(() => {
    const minDate = utils.getDefaultMinDate()
    courseService.findByCourseTypeId(id, minDate).then((response) => {
      dispatch(addCourses(response))
      setLoading(false)
    })
  }, [id, dispatch])

  const coursesInState = Object.values(state.courses)
    .filter((c) => c.courseTypeId === id)
    .sort(
      (a, b) => new Date(a.dateTimes[0].start) - new Date(b.dateTimes[0].start)
    )

  const coursesArrangedByMonth = arrangeByMonth(coursesInState)

  const renderItems = []

  if (coursesArrangedByMonth) {
    for (const [key, value] of Object.entries(coursesArrangedByMonth)) {
      renderItems.push(
        <Typography
          color="textPrimary"
          className={classes.monthSubHeading}
          variant="h6"
        >
          {key}
        </Typography>
      )
      value.forEach((course) =>
        renderItems.push(<Course key={course.id} course={course} />)
      )
    }
  }

  if (loading || !state.courseTypes[id]) return <Spinner />

  return (
    <Box className={classes.container}>
      <Button
        onClick={() =>
          history.push('/?category=' + state.courseTypes[id].category)
        }
        className={classes.backBtn}
        type="button"
        color="secondary"
        startIcon={<ArrowBackIcon />}
      >
        {t('coursetypes_title')}
      </Button>
      <Box textAlign="left">
        <Typography
          color="textPrimary"
          className={classes.courseTypeTitle}
          variant="h2"
        >
          {state.courseTypes[id].name[locale]}
        </Typography>
        <Typography className={classes.bodyText} color="textPrimary">
          {state.courseTypes[id].description[locale]}
        </Typography>
      </Box>
      {state.courseTypes[id].extraInfo ? (
        <div
          className="extraInfo"
          dangerouslySetInnerHTML={{
            __html: state.courseTypes[id].extraInfo[locale],
          }}
        ></div>
      ) : null}

      {coursesArrangedByMonth ? (
        renderItems.map((item, index) => <div key={index}>{item}</div>)
      ) : (
        <Typography style={{ marginTop: 20 }}>{t('no_courses')}</Typography>
      )}
    </Box>
  )
}

export default CourseList
