import React from 'react'
import {
  Box,
  makeStyles,
  Typography,
  Zoom,
  useMediaQuery,
} from '@material-ui/core'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Error as ErrorIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  notificationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    zIndex: 2,
    borderRadius: 12,
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      left: 45,
    },
    position: 'absolute',
  },
  icon: { fontSize: '4rem', margin: theme.spacing(2) },
  children: {
    zIndex: -1,
    visibility: 'hidden',
  },
}))

let timeoutID = null

const NotificationIcon = ({ error }) => {
  const classes = useStyles()

  if (error) return <ErrorIcon className={classes.icon} />
  return <CheckCircleOutlineIcon className={classes.icon} />
}

const NotificationWrapper = ({ open, setOpen, error, children }) => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const { t } = useTranslation()

  if (timeoutID) {
    clearTimeout(timeoutID)
  }

  timeoutID = setTimeout(() => {
    setOpen(false)
    timeoutID = null
  }, 2000)

  const backgroundColor = error
    ? {
        background:
          'radial-gradient(circle, rgba(191,74,49,1) 10%, rgba(135,33,33,1) 85%)',
      }
    : {
        background:
          'radial-gradient(circle, rgba(76,209,55,1) 10%, rgba(63,179,45,1) 85%)',
      }

  if (open) {
    return (
      <Zoom in={open}>
        <Box style={backgroundColor} className={classes.notificationWrapper}>
          {!isSmallScreen ? <NotificationIcon error={error} /> : null}
          <Typography variant="h4" className={classes.text}>
            {!error
              ? t('enroll_notification_success')
              : t('enroll_notification_error')}
          </Typography>
          <Box className={classes.children}>{children}</Box>
        </Box>
      </Zoom>
    )
  }

  return children
}
export default NotificationWrapper
