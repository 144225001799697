import React from 'react'
import { Grid } from '@material-ui/core'

/**
 * Helper component that aligns a MaterialUI icon vertically with text.
 */

const TextWithIcon = ({ icon, text, className }) => {
  return (
    <Grid
      className={className}
      container
      direction="row"
      spacing={1}
      alignItems="flex-start"
      style={{ marginBottom: -9 }}
    >
      <Grid item>{icon}</Grid>
      <Grid item>{text}</Grid>
    </Grid>
  )
}

export default TextWithIcon
