import React from 'react'
import { Card, CardContent, Typography, ButtonBase } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import i18next from 'i18next'

/**
 * Clickable course type as a card inside CourseTypes
 */

const useStyles = makeStyles({
  root: {
    color: '#e8e8e8',
    borderRadius: 15,
    '&:hover': {
      opacity: '80%',
    },
  },
  buttonBase: {
    width: '100%',
    height: '100%',
  },
  courseTypeHeader: {
    fontWeight: 'bold',
    paddingBottom: '12px',
  },
  contentArea: {
    paddingTop: '10px',
  },
  descriptionParagraph: {
    fontWeight: '500',
  },
})

const CourseTypeCard = ({ courseType }) => {
  const history = useHistory()
  const baseUrl = '/coursetypes'
  const classes = useStyles()
  const locale = i18next.language
  const theme = useTheme()
  const backgroundColor = theme.palette.courseTypeCategory[courseType.category]

  return (
    <Card
      elevation={3}
      style={{ backgroundColor }}
      className={classes.root}
      key={courseType.name}
    >
      <ButtonBase
        className={classes.buttonBase}
        onClick={() => {
          history.push(`${baseUrl}/${courseType.id}`)
        }}
      >
        <CardContent className={classes.contentArea}>
          <Typography
            className={classes.courseTypeHeader}
            variant="h5"
            component="h2"
          >
            {courseType.name[locale]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.descriptionParagraph}
          >
            {courseType.description[locale]}
          </Typography>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}

export default CourseTypeCard
