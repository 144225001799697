import moment from 'moment'
import i18next from 'i18next'

const parseCourseTitle = (course) => {
  const sortedDates = course.dateTimes.sort((a, b) => b.start - a.start)
  let start = new Date(sortedDates[0].start).toLocaleString('fi-FI', {
    weekday: 'long',
    month: 'numeric',
    day: 'numeric',
  })
  start = start.charAt(0).toUpperCase() + start.slice(1)
  const end = new Date(
    sortedDates[sortedDates.length - 1].start
  ).toLocaleDateString('fi-FI')

  const startTime = moment(sortedDates[0].start).format('H.mm')
  const endTime = moment(sortedDates[0].end).format('H.mm')

  return `${start} - ${end} klo ${startTime} - ${endTime}, osallistujat: ${
    course.participants ? course.participants : 0
  }/${course.maxParticipants}`
}

const parseDatesToArray = (selectedDates, duration) => {
  const dateTimeArr = []
  selectedDates.forEach((date) => {
    const start = date.toISOString()
    const end = moment(date).add(duration, 'minutes').toISOString()
    const obj = {
      start,
      end,
    }
    dateTimeArr.push(obj)
  })
  return dateTimeArr
}

const parseTime = (date) => {
  if (date instanceof Date) {
    let time = date
      .toLocaleTimeString('fi-FI', {
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace('.', ':')
    // Manual check in case Intl.DateTimeFormat 2-digit hour option is ignored by browser
    if (time.split(':')[0].length === 1 && Number(time.split(':')[0] < 10)) {
      time = `0${time}`
    }
    return time
  }
  return null
}

const parseDurationInHours = (dateTime) => {
  const startHours = new Date(dateTime.start).getHours()
  const endHours = new Date(dateTime.end).getHours()
  return endHours - startHours
}

const parseDurationInMinutes = (dateTime) => {
  const startDate = moment(dateTime.start)
  const endDate = moment(dateTime.end)
  return endDate.diff(startDate, 'minutes')
}

const parseDuration = (dateTime) => {
  const durationInMinutes = parseDurationInMinutes(dateTime)
  if (durationInMinutes > 120) {
    const d = moment.duration(durationInMinutes, 'minutes')
    // eslint-disable-next-line
    return d.hours() + 'h ' + (d.minutes() > 0 ? d.minutes() + 'min' : '')
  }
  return `${durationInMinutes}min`
}

const isEmpty = (obj) => {
  return !Object.keys(obj).length
}

const translatePriceLabel = (label) => {
  const locale = i18next.language
  switch (label) {
    case 'staff':
      return locale === 'fi'
        ? 'Helsingin yliopiston henkilökunta'
        : 'University of Helsinki staff'
    case 'student':
      return locale === 'fi' ? 'Opiskelija' : 'Student'
    case 'normal':
      return locale === 'fi' ? 'Normaali hinta' : 'Normal price'
    default:
      return null
  }
}

const generateRandomKey = () => {
  return Math.floor(Math.random() * new Date().getTime())
}

const getDefaultMinDate = () => {
  const date = new Date()
  const minDate = date.toISOString().split('T')[0]
  return minDate
}

export default {
  isEmpty,
  parseTime,
  parseCourseTitle,
  parseDatesToArray,
  translatePriceLabel,
  generateRandomKey,
  getDefaultMinDate,
  parseDuration,
  parseDurationInMinutes,
  parseDurationInHours,
}
