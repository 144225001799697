import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/prices`

const getAll = async () => {
  const response = await axios.get(baseUrl)
  return response.data
}
const update = async (type, prices, config) => {
  const response = await axios.put(`${baseUrl}/${type}`, prices, config)
  return response.data
}
export default { getAll, update }
