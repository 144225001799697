import React, { useState, useEffect, useContext } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

import courseService from '../../../services/courses'

import { StateContext } from '../../../state'
import { addCourses } from '../../../state/actions'

import CourseTypeDetails from './CourseTypeDetails'
import Course from '../Course'
import AddCourse from '../AddCourse'
import utils from '../../../utils'
/**
 * One coursetype listed inside Admin
 */

const useStyles = makeStyles((theme) => ({
  firstCourseType: {
    marginTop: theme.spacing(3),
  },
  courseType: {
    marginTop: theme.spacing(9),
  },
}))

const CourseList = ({ courses }) => {
  return courses.map((course) => <Course key={course.id} course={course} />)
}

const CourseType = ({ courseType, index }) => {
  const { state, dispatch } = useContext(StateContext)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true)
      const minDate = utils.getDefaultMinDate()
      const response = await courseService.findByCourseTypeId(
        courseType.id,
        minDate
      )
      dispatch(addCourses(response))
      setLoading(false)
    }
    fetchCourses()
  }, [courseType.id, dispatch]) // eslint-disable-line

  // Filter courses with corresponding coursetype id from state and order by start date
  const coursesInState = Object.values(state.courses)
    .filter((c) => c.courseTypeId === courseType.id)
    .sort(
      (a, b) => new Date(a.dateTimes[0].start) - new Date(b.dateTimes[0].start)
    )

  if (loading)
    return <CircularProgress disableShrink style={{ color: '#2064AE' }} />

  return (
    <div className={index === 0 ? classes.firstCourseType : classes.courseType}>
      <CourseTypeDetails courseType={courseType} courses={coursesInState} />
      <CourseList courses={coursesInState} />
      <AddCourse courseTypeId={courseType.id} />
    </div>
  )
}

export default CourseType
