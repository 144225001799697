import React from 'react'
import { Checkbox } from '@material-ui/core'
import { useFormContext, Controller } from 'react-hook-form'

/**
 *  Component for accepting terms and conditions with a checkbox
 */
const TermsCheckbox = () => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={`termsAccepted`}
      defaultValue={false}
      render={({ field: { onChange, value, name } }) => (
        <Checkbox
          onChange={onChange}
          checked={value}
          color="primary"
          name={name}
        />
      )}
    />
  )
}

export default TermsCheckbox
