import React, { useContext, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import CourseTypes from './components/User'
import Admin from './components/Admin'
import CourseList from './components/User/CourseList'
import userTheme from './themes/user'
import adminTheme from './themes/admin'
import courseTypeService from './services/coursetypes'
import courseUtils from './utils'

import { StateContext } from './state'
import { setCourseTypes, setCategory } from './state/actions'
import Archive from './components/Admin/Archive'
import useFirebase from './hooks/firebase'

function App() {
  const { state, dispatch } = useContext(StateContext)
  const firebase = useFirebase()

  useEffect(() => {
    const fetchCourseTypes = async () => {
      if (courseUtils.isEmpty(state.courseTypes)) {
        const response = await courseTypeService.getAll()
        dispatch(setCourseTypes(response))
      }
    }
    fetchCourseTypes()
  }, [dispatch, state.courseTypes])

  const { search } = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    let category = 'default'
    if (queryParams.has('category')) {
      category = queryParams.get('category')
    }
    dispatch(setCategory(category))
  }, [search])

  return (
    <div>
      <ThemeProvider theme={userTheme}>
        <CssBaseline />
        <Switch>
          <Route path="/coursetypes/:id">
            <CourseList />
          </Route>
          <Route path="/admin/archive">
            <ThemeProvider theme={adminTheme}>
              {firebase.isLoggedIn() ? <Archive /> : <Redirect to="/admin" />}
            </ThemeProvider>
          </Route>
          <Route path="/admin">
            <ThemeProvider theme={adminTheme}>
              <Admin />
            </ThemeProvider>
          </Route>
          <Route path="/">
            <CourseTypes />
          </Route>
        </Switch>
      </ThemeProvider>
    </div>
  )
}

export default App
