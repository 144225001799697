import React, { useContext, useState } from 'react'
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Collapse,
  Fade,
  InputLabel,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { StateContext } from '../../state'
import courseTypeService from '../../services/coursetypes'
import { addCourseTypes } from '../../state/actions'
import useFirebase from '../../hooks/firebase'

/**
 * Add new coursetype button/form inside Admin
 */

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
  },
  courseTypeCard: {
    backgroundColor: theme.palette.navyBlue.main,
    color: theme.palette.white,
    borderRadius: 12,
  },
  label: {
    color: theme.palette.white,
    fontWeight: '500',
    paddingTop: '10px',
  },
  textField: {
    margin: '10px 0',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.white,
    borderRadius: 5,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  addBtn: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '98%',
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    '&:hover': {
      border: `0.5px solid ${theme.palette.blue}`,
      color: theme.palette.blue,
    },
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  buttonContainer: {
    paddingLeft: '16px',
    paddingBottom: '14px',
  },
}))

const AddCourseType = () => {
  const [visible, setVisible] = useState(false)
  const [name, setName] = useState({ en: '', fi: '' })
  const [description, setDescription] = useState({ en: '', fi: '' })
  const classes = useStyles()
  const { state, dispatch } = useContext(StateContext)
  const firebase = useFirebase()

  const category = state.category

  const save = async () => {
    if (
      name.en === '' ||
      name.fi === '' ||
      description.en === '' ||
      description.fi === ''
    ) {
      return
    }
    const newCourseType = {
      name,
      description,
      category,
      extraInfo: {
        fi: '<h3>Lisätietoja</h3> <p>Peruutukset viimeistään 10 vuorokautta ennen kurssin alkua, muuten osallistuja on velvollinen maksamaan täyden hinnan koko kurssista. Huomioithan, että kurssien mahdollisia poissaoloja ei hyvitetä. Kursseille on lainattavissa mailoja. Mukaan tarvitset sisäkengät, juomapullon sekä pyyhkeen. Kurssit pidetään Meilahden Liikuntakeskuksen tiloissa osoitteessa Zaidankatu 9, Helsinki.</p> <h3>Maksu</h3> <p>Lasku lähetetään ensimmäisen pelikerran jälkeen sähköpostitse.</p>',
        en: '<h3>Information</h3> <p>Cancellations no later than 10 days before the start of the course, otherwise the participant is obligated to pay the full price of the course. Please note that any absences from the courses will not be reimbursed. Rackets can be borrowed for the courses. You will need indoor shoes, a drinking bottle and a towel. The courses will be held at the Meilahti Sports Center at Zaidankatu 9, Helsinki.</p> <h3>Payment</h3> <p>An invoice will be emailed after the first lesson.</p>',
      },
    }
    try {
      console.log(newCourseType)
      const config = await firebase.getTokenConfig()
      const response = await courseTypeService.create(newCourseType, config)
      if (response.status === 200) {
        console.log('coursetype creation successful:', response)
        dispatch(addCourseTypes([response.data]))
        setVisible(false)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <div className={classes.root}>
      {!visible ? (
        <Fade in={!visible}>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            className={classes.addBtn}
            onClick={() => setVisible(true)}
          >
            Uusi kurssityyppi
          </Button>
        </Fade>
      ) : null}
      <Collapse in={visible}>
        <Card className={classes.courseTypeCard}>
          <CardContent className={classes.cardContent}>
            <InputLabel className={classes.label}>
              Kurssityypin nimi:
            </InputLabel>
            <TextField
              className={classes.textField}
              onChange={(event) => setName({ ...name, fi: event.target.value })}
              aria-label="coursetype-name-fi"
            />
            <InputLabel className={classes.label}>
              Kurssityypin nimi (ENG):
            </InputLabel>
            <TextField
              className={classes.textField}
              onChange={(event) => setName({ ...name, en: event.target.value })}
              aria-label="coursetype-name-en"
            />
            <InputLabel className={classes.label}>
              Kurssityypin kuvaus:
            </InputLabel>
            <TextField
              multiline
              className={classes.textField}
              onChange={(event) =>
                setDescription({ ...description, fi: event.target.value })
              }
              aria-label="coursetype-description-fi"
            />
            <InputLabel className={classes.label}>
              Kurssityypin kuvaus (ENG):
            </InputLabel>
            <TextField
              multiline
              className={classes.textField}
              onChange={(event) =>
                setDescription({ ...description, en: event.target.value })
              }
              aria-label="coursetype-description-en"
            />
          </CardContent>
          <CardActions className={classes.buttonContainer}>
            <Button className={classes.saveBtn} onClick={() => save()}>
              Tallenna
            </Button>
            <Button onClick={() => setVisible(false)}>Peruuta</Button>
          </CardActions>
        </Card>
      </Collapse>
    </div>
  )
}

export default AddCourseType
