import React, { useContext } from 'react'
import {
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Button,
  makeStyles,
  Box,
} from '@material-ui/core'
import { MailOutline as MailOutlineIcon } from '@material-ui/icons'
import courseService from '../../../services/courses'
import AddParticipant from './AddParticipant'
import ParticipantInfo from './ParticipantInfo'

import { StateContext } from '../../../state'
import { updateCourse } from '../../../state/actions'
import useFirebase from '../../../hooks/firebase'

/**
 * Holds participant info inside EditCourse
 */

const useStyles = makeStyles((theme) => ({
  MuiButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
    fontWeight: 600,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
  },
}))

const Participants = ({ course, participants, setParticipants, archived }) => {
  const { dispatch } = useContext(StateContext)
  const classes = useStyles()
  const firebase = useFirebase()

  const handleParticipantRemove = async (participant) => {
    try {
      const config = await firebase.getTokenConfig()
      const response = await courseService.removeParticipant(
        course.id,
        participant.id,
        config
      )
      if (response.status === 200) {
        setParticipants(participants.filter((p) => p.id !== participant.id))
        dispatch(
          updateCourse({ ...course, participants: course.participants - 1 })
        )
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const hideWhenLessThanTwo =
    course.participants > 1 ? { display: 'inherit' } : { display: 'none' }

  return (
    <div>
      <Typography className={classes.title}>Ilmoittautuneet</Typography>
      <TableContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableBody>
            {participants.length > 0
              ? participants.map((participant) => (
                  <ParticipantInfo
                    key={`${participant.name}${Math.random() * new Date().getTime()}`}
                    participant={participant}
                    handleParticipantRemove={handleParticipantRemove}
                    archived={archived}
                  />
                ))
              : null}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                <AddParticipant
                  archived={archived}
                  course={course}
                  setParticipants={setParticipants}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.flexRow}>
        <Button
          style={hideWhenLessThanTwo}
          startIcon={<MailOutlineIcon />}
          variant="contained"
          className={classes.MuiButton}
          href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${participants.map(
            (p) => `${p.email};`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Sähköposti kaikille
        </Button>
      </Box>
    </div>
  )
}

export default Participants
