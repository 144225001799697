import React from 'react'
import { TextField, makeStyles, Typography } from '@material-ui/core'

/**
 * Field to include extra info about the course
 */

const useStyles = makeStyles(() => ({
  textField: {
    width: 100,
    padding: 5,
  },
  extraInfoField: {
    width: '100%',
    paddingLeft: 0,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
}))

const ExtraInfo = ({ course, archived, handleExtraInfoChange }) => {
  const classes = useStyles()

  if (!course.extraInfo) return null

  return (
    <div>
      <Typography className={classes.title}>
        Kurssikohtaiset lisätiedot:
      </Typography>
      <TextField
        disabled={archived}
        className={[classes.textField, classes.extraInfoField].join(' ')}
        onChange={(event) => handleExtraInfoChange('fi', event.target.value)}
        type="text"
        defaultValue={course.extraInfo.fi}
        placeholder="Lisätietoja kurssista, esim. poikkeukselliset aikataulut tai eri valmentaja."
        InputProps={{
          className: classes.inputElement,
        }}
      />
      <Typography className={classes.title}>
        Kurssikohtaiset lisätiedot (ENG):
      </Typography>
      <TextField
        disabled={archived}
        className={[classes.textField, classes.extraInfoField].join(' ')}
        onChange={(event) => handleExtraInfoChange('en', event.target.value)}
        type="text"
        defaultValue={course.extraInfo.en}
        placeholder="Additional info about the course, e.g., exceptional schedules or a different coach."
        InputProps={{
          className: classes.inputElement,
        }}
      />
    </div>
  )
}

export default ExtraInfo
