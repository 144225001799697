import React, { useState, useContext, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { makeStyles } from '@material-ui/core'

import courseUtils from '../../../utils'
import { StateContext } from '../../../state'
import courseService from '../../../services/courses'
import useFirebase from '../../../hooks/firebase'

const useStyles = makeStyles(() => ({
  exportLinkContainer: {
    paddingTop: '15px',
    paddingRight: '25px',
    fontWeight: '650',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
}))

const ExportData = ({ courses }) => {
  const [exportableCourses, setExportableCourses] = useState(null)
  const { state } = useContext(StateContext)
  const firebase = useFirebase()

  const classes = useStyles()

  const fillParticipants = async (courses) => {
    if (!courses) return

    const config = await firebase.getTokenConfig()

    console.log(courses)

    const updatedCourses = await Promise.all(
      courses.map(async (course) => {
        const participants = await courseService.getParticipants(
          course.id,
          config
        )
        course.participantInfo = participants
        return course
      })
    )

    console.log(updatedCourses)

    setExportableCourses(updatedCourses)
  }

  const generateCSVData = (courses) => {
    if (!courses) return []

    const csvData = [['Kurssityyppi', 'Kurssi', 'Osallistuja', 'Sähköposti']]

    courses.forEach((course) => {
      const courseTypeTitle = state.courseTypes
        ? state.courseTypes[course.courseTypeId].name.fi
        : '-'

      if (course.participantInfo.length === 0) {
        csvData.push([
          courseTypeTitle,
          courseUtils.parseCourseTitle(course),
          '',
          '',
        ])
      } else {
        const participantData = course.participantInfo.map(
          (participant, index) => {
            if (index === 0)
              return [
                courseTypeTitle,
                courseUtils.parseCourseTitle(course),
                participant.name,
                participant.email,
              ]
            return ['', '', participant.name, participant.email]
          }
        )
        csvData.push(...participantData)
      }
      csvData.push(['', '', '', ''])
    })
    return csvData
  }

  useEffect(() => {
    fillParticipants(courses)
  }, [courses]) // eslint-disable-line

  return exportableCourses && exportableCourses.length > 0 ? (
    <div className={classes.exportLinkContainer}>
      <CSVLink
        data={generateCSVData(exportableCourses)}
        filename={`kurssit_${new Date().toLocaleDateString()}.csv`}
        className="csv-download-button"
      >
        Lataa CSV-muodossa
      </CSVLink>
    </div>
  ) : null
}

export default ExportData
