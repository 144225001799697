import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  Box,
} from '@material-ui/core'
import {
  Person as PersonIcon,
  FiberManualRecord as CourseFullIcon,
} from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import i18next from 'i18next'

import EnrollmentForm from '../EnrollmentForm'
import CourseSchedule from './Schedule'
import useStyles from './styles'
import NotificationWrapper from './NotificationWrapper'
import utils from '../../../utils'

/**
 * A single course accordion inside Courses
 * Holds CourseSchedule and Enrollmentform
 */

const Course = ({ course }) => {
  const { t } = useTranslation()
  const [accordionOpen, setAccordionOpen] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const classes = useStyles()
  const isFull = (c) => c.participants === c.maxParticipants
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const [notify, setNotify] = useState(false)
  const [error, setError] = useState(false)
  const locale = i18next.language

  const toggleForm = () => {
    if (!accordionOpen) {
      setAccordionOpen(!accordionOpen)
    }
    setFormOpen(true)
  }

  const handleAccordionClick = () => {
    if (formOpen) {
      setFormOpen(false)
      setAccordionOpen(false)
    } else {
      setAccordionOpen(false)
    }
  }

  const hideWhenFormOpen = {
    display: formOpen ? 'none' : 'inherit',
    width: 'fit-content',
  }

  const enrollmentFormProps = {
    course,
    setFormOpen,
    setAccordionOpen,
    setNotify,
    setError,
  }

  const participantIcons = []
  for (let i = 0; i < course.maxParticipants; i += 1) {
    // Check whether to render blue or gray participant icon
    if (i < course.participants) {
      participantIcons.push(<PersonIcon key={i} className={classes.blue} />)
    } else {
      participantIcons.push(
        <PersonIcon key={i} className={classes.lightGray} />
      )
    }
  }

  return (
    <NotificationWrapper open={notify} setOpen={setNotify} error={error}>
      <Accordion
        elevation={3}
        style={{
          zIndex: notify ? -1 : 1,
          backgroundColor: isFull(course) ? '#EFEFEF' : '#F8F8F8',
        }}
        expanded={accordionOpen}
      >
        <AccordionSummary
          IconButtonProps={{ className: classes.expandIcon }}
          expandIcon={accordionOpen ? <ExpandMoreIcon /> : null}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={handleAccordionClick}
          className={classes.accordionSummary}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              position: 'absolute',
              top: 0,
              left: !isSmallScreen ? 10 : 0,
            }}
            className={isFull(course) ? classes.grayText : classes.greenText}
          >
            <CourseFullIcon className={classes.courseFullIcon} />
            <Typography
              className={
                isFull(course)
                  ? [classes.smallText, classes.grayText].join(' ')
                  : [classes.smallText, classes.greenText].join(' ')
              }
              style={{ marginLeft: 5 }}
            >
              {isFull(course)
                ? t('accordion_body_full')
                : t('accordion_body_available')}
            </Typography>
          </Box>
          <Grid
            className={classes.courseDetailsContainer}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={12} sm="auto" md={7}>
              <Typography
                style={{ marginLeft: !isSmallScreen ? 15 : 3 }}
                className={classes.smallText}
              >
                {t('accordion_body_schedule')}
              </Typography>
              <CourseSchedule course={course} />
            </Grid>
            <Grid
              item
              xs="auto"
              className={isSmallScreen ? classes.flexGrowRow : null}
            >
              <div style={{ marginBottom: 16 }}>
                <div style={{ display: 'table' }}>
                  <Typography className={classes.smallText}>
                    {t('accordion_body_participants')}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                    className={[
                      classes.flexRow,
                      classes.blue,
                      classes.semiBold,
                    ].join(' ')}
                  >
                    <PersonIcon />
                    {`${course.participants}/${course.maxParticipants}`}
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography className={classes.smallText}>
                  {t('accordion_body_duration')}
                </Typography>
                <Typography
                  className={[classes.blue, classes.semiBold].join(' ')}
                >
                  {course.dateTimes.length} x{' '}
                  {utils.parseDuration(course.dateTimes[0])}
                </Typography>
              </div>
            </Grid>
            {isSmallScreen && course.extraInfo[locale] ? (
              <Grid className={classes.extraInfo} item xs={12}>
                <Typography className={classes.smallText}>
                  {t('accordion_body_extrainfo')}
                </Typography>
                <Typography className={classes.regular}>
                  {course.extraInfo[locale]}
                </Typography>
              </Grid>
            ) : null}
            <Grid
              className={
                isSmallScreen
                  ? classes.enrollBtnContainer
                  : [classes.enrollBtnContainer, classes.alignRight].join(' ')
              }
              item
              xs={12}
              sm="auto"
              md={3}
            >
              {!isFull(course) ? (
                <FormControlLabel
                  style={hideWhenFormOpen}
                  className={
                    isSmallScreen ? classes.fillParent : classes.alignRight
                  }
                  aria-label="Enroll"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Button
                      variant="contained"
                      onClick={toggleForm}
                      className={
                        isSmallScreen
                          ? [classes.mobileEnrollBtn, classes.enrollBtn].join(
                              ' '
                            )
                          : classes.enrollBtn
                      }
                      size={isSmallScreen ? 'small' : 'medium'}
                    >
                      {t('btn_enroll')}
                    </Button>
                  }
                />
              ) : (
                <FormControlLabel
                  aria-label="Full"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  className={
                    isSmallScreen ? classes.fillParent : classes.alignRight
                  }
                  control={
                    <Button
                      size={isSmallScreen ? 'small' : 'medium'}
                      variant="contained"
                      className={
                        isSmallScreen
                          ? [
                              classes.mobileEnrollBtn,
                              classes.courseFullBtn,
                            ].join(' ')
                          : classes.courseFullBtn
                      }
                    >
                      {t('btn_full')}
                    </Button>
                  }
                />
              )}
            </Grid>
            {!isSmallScreen && course.extraInfo[locale] ? (
              <Grid
                style={{ marginLeft: 20 }}
                className={classes.extraInfo}
                item
                sm={12}
              >
                <Typography className={classes.smallText}>
                  {t('accordion_body_extrainfo')}
                </Typography>
                <Typography className={classes.regular}>
                  {course.extraInfo[locale]}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.flexColumn}>
          {formOpen ? <EnrollmentForm {...enrollmentFormProps} /> : null}
        </AccordionDetails>
      </Accordion>
    </NotificationWrapper>
  )
}

export default Course
