import React from 'react'
import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import utils from '../../../utils'

/**
 * Component for selecting participant price type
 */

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
}))

const PriceSelect = ({ prices, participantNr }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { control } = useFormContext()

  const defaultValue = Object.keys(prices).sort()[0]

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <InputLabel id="simple-select-outlined-label">
        {t('form_pricerange')}
      </InputLabel>
      <Controller
        control={control}
        name={`priceRange[participant${participantNr}]`}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            labelId="simple-select-outlined-label"
            id="simple-select-outlined"
            label={t('form_pricerange')}
            required
            native
          >
            {Object.keys(prices).map((priceRange) => (
              <option
                className={classes.breakSpaces}
                key={utils.generateRandomKey()}
                value={priceRange}
              >
                {utils.translatePriceLabel(priceRange)} ({prices[priceRange]} €)
              </option>
            ))}
          </Select>
        )}
      />
    </FormControl>
  )
}

export default PriceSelect
