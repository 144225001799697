import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Person } from '@material-ui/icons'

import TextWithIcon from '../../TextWithIcon'
import PriceSelect from './PriceSelect'

/**
 * Input fields inside EnrollmentForm.
 * If prop withMsg is false, message field is left out.
 */

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  inlineRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    columnGap: theme.spacing(1),
    rowGap: '8px',
    width: '95%',
    margin: theme.spacing(1),
    '& .MuiTextField-root': {
      margin: 0,
    },
  },
  textField: {
    width: '95%',
  },
  participantIcon: { color: theme.palette.gray, paddingLeft: 5 },
}))

const ParticipantInfo = ({ withMsg, participantNr, course }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Grid item xs={12} sm={6} lg={6} xl={6} className={classes.root}>
      <TextWithIcon
        icon={<Person className={classes.participantIcon} color="secondary" />}
        text={
          <Typography component="p">
            {`${t('participant')} ${participantNr}`}
          </Typography>
        }
      />
      <TextField
        className={classes.textField}
        {...register(`name[participant${participantNr}]`, {
          required: t('form_required'),
        })}
        required
        variant="outlined"
        label={t('form_name')}
        type="text"
        id="name"
      />
      <ErrorMessage
        errors={errors}
        name={`name[participant${participantNr}]`}
        render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
      />
      <TextField
        className={classes.textField}
        {...register(`email[participant${participantNr}]`, {
          required: t('form_required'),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t('form_email_validation'),
          },
        })}
        required
        variant="outlined"
        label={t('form_email')}
        type="email"
        id="email"
      />
      <ErrorMessage
        errors={errors}
        name={`email[participant${participantNr}]`}
        render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
      />
      <TextField
        className={classes.textField}
        {...register(`address[participant${participantNr}]`, {
          required: t('form_required'),
        })}
        required
        variant="outlined"
        label={t('form_address')}
        type="text"
        id="address"
      />
      <ErrorMessage
        errors={errors}
        name={`address[participant${participantNr}]`}
        render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
      />
      <div className={classes.inlineRoot}>
        <TextField
          {...register(`postalCode[participant${participantNr}]`, {
            required: t('form_required'),
          })}
          required
          variant="outlined"
          label={t('form_postal_code')}
          type="text"
          id="postalCode"
        />
        <TextField
          {...register(`postOffice[participant${participantNr}]`, {
            required: t('form_required'),
          })}
          required
          variant="outlined"
          label={t('form_post_office')}
          type="text"
          id="postOffice"
        />
        <ErrorMessage
          errors={errors}
          name={`postalCode[participant${participantNr}]`}
          render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
        />
        <ErrorMessage
          errors={errors}
          name={`postOffice[participant${participantNr}]`}
          render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
        />
      </div>
      <PriceSelect prices={course.prices} participantNr={participantNr} />
      {withMsg ? (
        <>
          <TextField
            className={classes.textField}
            {...register(`message`, {
              required: false,
              minLength: {
                value: 10,
                message: t('form_msg_validation_min'),
              },
              maxLength: {
                value: 500,
                message: t('form_msg_validation_max'),
              },
            })}
            multiline
            variant="outlined"
            placeholder={t('form_msg_placeholder')}
            label={t('form_msg_label')}
            type="text"
            id="message"
          />
          <ErrorMessage
            errors={errors}
            name="message"
            render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
          />
        </>
      ) : null}
    </Grid>
  )
}

export default ParticipantInfo
