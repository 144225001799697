import React from 'react'
import {
  List,
  ListItem,
  Typography,
  Divider,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule'
import i18next from 'i18next'
import moment from 'moment'

/**
 * Displays a single course schedule inside Course
 */

const useStyles = makeStyles((theme) => ({
  mobileJustifyCenter: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  listItem: {
    paddingLeft: 13,
    paddingRight: 13,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 16,
      paddingBottom: 2,
    },
  },
  muiListRoot: {
    [theme.breakpoints.down('xl')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  mobileMarginLeft: {
    marginLeft: '7vw',
  },
  courseDateTime: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 115,
    },
    [theme.breakpoints.up('sm')]: {
      width: 133,
    },
  },
  weekDay: {
    fontSize: '14px',
    color: '#b0b0b0',
    fontWeight: 600,
  },
  date: {
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.palette.blue,
    marginLeft: '5px',
  },
  scheduleIcon: { fontSize: 15, color: theme.palette.blue, margin: 2 },
  startTime: { fontSize: 12, fontWeight: 600, color: theme.palette.blue },
  arrow: { color: theme.palette.gray, fontSize: 12, fontWeight: 600 },
  endtime: { color: theme.palette.lightGray, fontSize: 12, fontWeight: 600 },
}))

const Schedule = ({ course }) => {
  const localeString = `${i18next.language}-FI`

  const classes = useStyles()

  const mobileMarginLeft = useMediaQuery(
    '(min-width: 300px) and (max-width: 400px)'
  )

  const parseDateString = (dateTimeObject) => {
    const date = dateTimeObject.start
    return new Date(date).toLocaleString(localeString, {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
    }).replace(',', '')
  }

  const parseTimeString = (date) => {
    return moment(date).format('H.mm')
  }

  if (!course || !course.dateTimes) {
    return null
  }

  return (
    <div className={classes.mobileJustifyCenter}>
      <List
        className={
          mobileMarginLeft && course.dateTimes.length > 1
            ? [classes.muiListRoot, classes.mobileMarginLeft].join(' ')
            : classes.muiListRoot
        }
        size="small"
        aria-label="a dense table"
      >
        {course.dateTimes.map((dateTime, index) => (
          <div key={dateTime.start} style={{ display: 'flex' }}>
            <div key={dateTime.start} className={classes.courseDateTime}>
              <ListItem className={classes.listItem}>
                {/* Weekday */}
                <Typography className={classes.weekDay}>
                  {parseDateString(dateTime).toUpperCase().split(' ')[0]}
                </Typography>
                {/* Date */}
                <Typography className={classes.date}>
                  {parseDateString(dateTime).toUpperCase().split(' ')[1]}
                </Typography>
              </ListItem>
              <ListItem className={classes.listItem} style={{ paddingTop: 0 }}>
                {/* Start time - end time */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <ScheduleIcon className={classes.scheduleIcon} />
                  <span className={classes.startTime}>
                    {parseTimeString(dateTime.start)}
                  </span>
                  <span className={classes.arrow}>
                    {String.fromCharCode(8594)}
                  </span>
                  <span className={classes.endtime}>
                    {parseTimeString(dateTime.end)}
                  </span>
                </div>
              </ListItem>
            </div>
            {index < course.dateTimes.length - 1 ? (
              <Divider
                style={{ margin: '2px 0' }}
                orientation="vertical"
                flexItem
              />
            ) : null}
          </div>
        ))}
      </List>
    </div>
  )
}

export default Schedule
