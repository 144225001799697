import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/courses`

const addCourse = async (course, config) => {
  const response = await axios.post(baseUrl, course, config)
  return response.data
}

const deleteCourse = async (id, config) => {
  const response = await axios.delete(`${baseUrl}/${id}`, config)
  return response
}

const getAll = async () => {
  const response = await axios.get(baseUrl)
  return response.data
}

const findByCourseTypeId = async (courseTypeId, minDate) => {
  const response = await axios.get(
    `${baseUrl}?minDate=${minDate}&courseTypeId=${courseTypeId}`
  )
  return response.data
}

const findByMinDate = async (minDate) => {
  const response = await axios.get(`${baseUrl}?minDate=${minDate}`)
  return response.data
}

const findById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const getParticipants = async (id, config) => {
  const response = await axios.get(`${baseUrl}/${id}/participants`, config)
  return response.data
}

const removeParticipant = async (courseId, participantId, config) => {
  const response = await axios.delete(
    `${baseUrl}/${courseId}/participants/${participantId}`,
    config
  )
  return response
}

const enroll = async (
  id,
  sendAdminEmail,
  sendParticipantsEmail,
  requestBody
) => {
  const config = {
    params: {
      sendAdminEmail,
      sendParticipantsEmail,
    },
  }

  const response = await axios.post(
    `${baseUrl}/${id}/participants`,
    requestBody,
    config
  )
  return response
}

const updateCourse = async (id, updatedCourse, config) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedCourse, config)
  return response
}

export default {
  getAll,
  findByCourseTypeId,
  enroll,
  findById,
  addCourse,
  deleteCourse,
  getParticipants,
  removeParticipant,
  updateCourse,
  findByMinDate,
}
