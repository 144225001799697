import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'

// eslint-disable-next-line
let userTheme = createTheme({
  palette: {
    primary: {
      main: '#0097E6',
      contrastText: '#fafafa',
    },
    secondary: {
      main: '#7d7d7d',
      contrastText: '#fafafa',
    },
    success: {
      main: '#4CD137',
      contrastText: '#fafafa',
    },
    text: {
      primary: '#474747',
    },
    blue: '#0097E6',
    lightGray: '#B0B0B0',
    darkGreen: '#268709',
    gray: '#B4B4B4',
    courseTypeCategory: {
      default: '#219bdb',
      weekend: '#01a636',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'],
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fcfcfc',
        },
      },
    },
    MuiAccordion: {
      root: {
        marginTop: 14,
        '&:before': {
          display: 'none',
        },
        backgroundColor: '#f8f8f8',
      },
      rounded: {
        borderRadius: 25,
        '&:first-child': {
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        },
        '&:last-child': {
          borderBottomLeftRadius: 25,
          borderBottomRightRadius: 25,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiPaper: {
      root: {
        padding: 10,
        margin: '10px 0px',
      },
      elevation3: {
        boxShadow:
          '0px 3px 3px -2px rgb(113 113 113 / 20%), 0px 3px 7px 0px rgb(116 116 116 / 14%), 0px 1px 10px 0px rgb(122 121 121 / 12%)',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
      },
      content: {
        maxWidth: '100%',
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0px',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
      edgeEnd: {
        marginRight: 0,
      },
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'break-spaces',
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'break-spaces',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4.5,
      },
    },
    MuiCollapse: {
      container: {
        paddingBottom: 8,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 14,
        paddingRight: 14,
      },
    },
  },
})

userTheme = responsiveFontSizes(userTheme)

export default userTheme
