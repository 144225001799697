import firebase from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    }
    this.auth = firebase.auth()
  }

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  getTokenConfig = async () => {
    const idToken = await this.auth.currentUser.getIdToken()

    const authConfig = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return authConfig
  }

  isLoggedIn = () => {
    const user = firebase.auth().currentUser
    if (user) {
      return true
    } else {
      return false
    }
  }

  signOut = () => this.auth.signOut()
}

export default Firebase
