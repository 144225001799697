import React from 'react'
import {
  Input,
  MenuItem,
  FormControl,
  Select,
  makeStyles,
} from '@material-ui/core'
import { Public as Language } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'block',
    margin: theme.spacing(1),
    marginLeft: 'auto',
    width: '30vw',
  },
  languageIcon: {
    fontSize: 20,
    marginRight: 10,
  },
  selectValue: {
    display: 'flex',
    padding: theme.spacing(0.5),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const LanguageSelect = () => {
  const [selectedLocale, setSelectedLocale] = React.useState(i18next.language)
  const { i18n } = useTranslation()
  const classes = useStyles()

  const locales = ['fi', 'en']

  const handleChange = (event) => {
    setSelectedLocale(event.target.value)
    i18n.changeLanguage(event.target.value)
    const momentLocale = event.target.value === 'fi' ? 'fi' : 'en-gb'
    moment.locale(momentLocale)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          id="language-select"
          value={selectedLocale}
          renderValue={(value) => {
            return (
              <div className={classes.selectValue}>
                <Language className={classes.languageIcon} />
                {value === 'fi' ? 'Suomi' : 'English'}
              </div>
            )
          }}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
        >
          {locales.map((locale) => (
            <MenuItem key={locale} value={locale}>
              {locale === 'fi' ? 'Suomi' : 'English'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default LanguageSelect
