import React, { useContext, useState } from 'react'
import {
  Button,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  FormControl,
  useMediaQuery,
} from '@material-ui/core'

import { StateContext } from '../../../state'
import courseService from '../../../services/courses'

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}))

const SearchCourses = ({ setCourses, setLoading }) => {
  const classes = useStyles()
  const [minDate, setMinDate] = useState(new Date().toISOString().split('T')[0])
  const { state } = useContext(StateContext)
  const [courseType, setCourseType] = useState('*')
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const handleSearch = async () => {
    setCourses([])
    setLoading(true)
    let response = null
    if (courseType === '*') {
      response = await courseService.findByMinDate(minDate)
    } else {
      response = await courseService.findByCourseTypeId(courseType, minDate)
    }
    if (response.length > 0) {
      setCourses(response)
    }
    setLoading(false)
  }

  const handleChange = (event) => {
    setCourseType(event.target.value)
  }

  const handleDateChange = (event) => {
    setMinDate(event.target.value)
  }

  const flexDirection = isSmallScreen
    ? { flexDirection: 'column' }
    : { flexDirection: 'row' }

  return (
    <Paper style={flexDirection} className={classes.root}>
      <InputLabel className={classes.label} id="coursetype-select-label">
        Kurssityyppi:
      </InputLabel>
      <FormControl className={classes.formControl}>
        <Select
          labelId="coursetype-select-label"
          value={courseType}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {Object.values(state.courseTypes).map((cType) => (
            <MenuItem key={cType.id} value={cType.id}>
              {cType.name.fi}
            </MenuItem>
          ))}
          <MenuItem value="*">Kaikki</MenuItem>
        </Select>
      </FormControl>
      <InputLabel className={classes.label}>Näytä kurssit alkaen:</InputLabel>
      <TextField
        style={{ alignSelf: 'center' }}
        type="date"
        value={minDate}
        onChange={handleDateChange}
      />
      <Button onClick={() => handleSearch()}>Hae</Button>
    </Paper>
  )
}

export default SearchCourses
