import React, { useState, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  makeStyles,
  Box,
} from '@material-ui/core'
import { Euro } from '@material-ui/icons'

import Dates from './Course/Dates'
import MaxParticipants from './Course/MaxParticipants'
import TextWithIcon from '../TextWithIcon'
import ExtraInfo from './Course/ExtraInfo'

import courseService from '../../services/courses'
import { StateContext } from '../../state'
import useFirebase from '../../hooks/firebase'

/**
 * Component for adding a new course
 */

const useStyles = makeStyles((theme) => ({
  MuiAccordion: {
    backgroundColor: theme.palette.whiteBox,
    color: theme.palette.blue,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  MuiButton: {
    margin: 10,
    width: 220,
    height: 40,
    fontWeight: 600,
  },
  verticalPadding: {
    padding: '5px 0',
  },
  rightColumn: {
    paddingLeft: '11% !important',
  },
  accordionHeader: {
    fontWeight: 500,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 5,
  },
}))

const useDateTableRowStyles = makeStyles((theme) => ({
  tableCell: {
    border: `solid ${theme.palette.blue} 2px`,
    borderLeft: 'none',
    height: 10,
    width: 150,
    fontSize: 14,
    fontWeight: 500,
  },
  ordinalCell: {
    width: 40,
    backgroundColor: theme.palette.blue,
    color: theme.palette.white,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
}))

const AddCourse = ({ courseTypeId }) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  const dateTableRowClasses = useDateTableRowStyles()
  // eslint-disable-next-line
  const [edited, setEdited] = useState(false)
  const firebase = useFirebase()
  const { dispatch } = useContext(StateContext)

  // eslint-disable-next-line
  const [course, setCourse] = useState({
    courseTypeId,
    maxParticipants: 4,
    priceType: 'morning',
    dateTimes: [],
    extraInfo: {
      fi: null,
      en: null,
    },
  })

  const onCancel = () => {
    setExpanded(false)
  }

  const onSubmit = async () => {
    try {
      const config = await firebase.getTokenConfig()
      const response = await courseService.addCourse(course, config)
      if (response.id) {
        dispatch({ type: 'ADD_COURSES', payload: [response] })
        setExpanded(false)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const handlePriceChange = (event) => {
    setCourse({ ...course, priceType: event.target.value })
  }

  const changeMaxParticipants = (event) => {
    setCourse({
      ...course,
      maxParticipants: Number(event.target.value),
    })
    setEdited(true)
  }

  const handleExtraInfoChange = (locale, value) => {
    if (value === '') setCourse({ ...course, extraInfo: null })
    setCourse({
      ...course,
      extraInfo: { ...course.extraInfo, [locale]: value },
    })
    setEdited(true)
  }

  return (
    <Accordion
      className={classes.MuiAccordion}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography className={classes.accordionHeader}>
          + Uusi kurssi:
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs>
            <Dates
              course={course}
              setEditedCourse={setCourse}
              setEdited={setEdited}
              tableRowClasses={dateTableRowClasses}
            />
          </Grid>
          <Grid item xs className={classes.rightColumn}>
            <MaxParticipants
              course={course}
              archived={false}
              changeMaxParticipants={changeMaxParticipants}
            />
            <Box mt={2} mb={1}>
              <TextWithIcon
                icon={<Euro />}
                text={
                  <Typography display="inline" className={classes.title}>
                    Hintaluokka
                  </Typography>
                }
              />
            </Box>
            <Select
              className={classes.verticalPadding}
              value={course.priceType}
              labelId="price-select"
              onChange={handlePriceChange}
            >
              <MenuItem value="morning">Aamu</MenuItem>
              <MenuItem value="evening">Ilta</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <ExtraInfo
              course={course}
              archived={false}
              handleExtraInfoChange={handleExtraInfoChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.btnContainer}>
            <Button
              className={classes.MuiButton}
              onClick={() => onCancel()}
              color="secondary"
              variant="contained"
            >
              Peruuta
            </Button>
            <Button
              className={classes.MuiButton}
              onClick={() => onSubmit()}
              color="primary"
              variant="contained"
            >
              Luo kurssi
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default AddCourse
