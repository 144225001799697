import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  InputLabel,
} from '@material-ui/core'
import { Edit, Cancel, Save, Delete } from '@material-ui/icons'
import { useForm, Controller } from 'react-hook-form'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { StateContext } from '../../../state'
import {
  deleteCourseType,
  updateCourseType,
  deleteCourse,
} from '../../../state/actions'

import courseTypeService from '../../../services/coursetypes'
import useFirebase from '../../../hooks/firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    marginRight: 10,
    fontWeight: 600,
    backgroundColor: theme.palette.white,
  },
  blueText: { color: theme.palette.blue },
  redText: { color: theme.palette.secondary.main },
  header: {
    fontSize: 25,
    fontWeight: 700,
    paddingBottom: '6px',
  },
  content: {
    fontSize: 14,
    fontWeight: 500,
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  extraInfoEditor: {
    border: '1px solid #F1F1F1',
    borderRadius: '4px',
    padding: '0 10px',
  },
}))

const CourseTypeDetails = ({ courseType, courses }) => {
  const [open, setOpen] = useState(false)
  const [extraInfoEditorStateFi, setExtraInfoEditorStateFi] = useState(null)
  const [extraInfoEditorStateEn, setExtraInfoEditorStateEn] = useState(null)
  const { handleSubmit, control, register } = useForm()
  const classes = useStyles()
  const { dispatch } = useContext(StateContext)
  const firebase = useFirebase()

  const toggle = () => setOpen(!open)

  useEffect(() => {
    if (courseType.extraInfo) {
      const contentBlockFi = htmlToDraft(courseType.extraInfo.fi)
      const contentStateFi = ContentState.createFromBlockArray(
        contentBlockFi.contentBlocks
      )
      const editorStateFi = EditorState.createWithContent(contentStateFi)
      setExtraInfoEditorStateFi(editorStateFi)

      const contentBlockEn = htmlToDraft(courseType.extraInfo.en)
      const contentStateEn = ContentState.createFromBlockArray(
        contentBlockEn.contentBlocks
      )
      const editorStateEn = EditorState.createWithContent(contentStateEn)
      setExtraInfoEditorStateEn(editorStateEn)
    }
  }, [courseType.extraInfo])

  const onUpdate = async (data, event) => {
    event.preventDefault()
    const { id } = courseType
    const updatedCourseType = { ...data }
    console.log('submit', updatedCourseType)
    try {
      const config = await firebase.getTokenConfig()
      const response = await courseTypeService.update(
        id,
        updatedCourseType,
        config
      )
      if (response.status === 200) {
        dispatch(updateCourseType(response.data))
        toggle()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = async (courseTypeToDelete) => {
    try {
      if (
        // eslint-disable-next-line
        window.confirm(
          `Haluatko varmasti poistaa kurssityypin ${courseTypeToDelete.name.fi}?`
        )
      ) {
        const config = await firebase.getTokenConfig()
        const response = await courseTypeService.remove(
          courseTypeToDelete.id,
          config
        )
        if (response.status === 200) {
          console.log(
            'successfully removed courseType ',
            courseTypeToDelete.name.fi
          )
          // Remove corresponding courses from state
          courses.forEach((c) => {
            dispatch(deleteCourse(c))
          })
          // Remove coursetype from state
          dispatch(deleteCourseType(courseTypeToDelete))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (open) {
    return (
      <form
        className={[classes.root, classes.form].join(' ')}
        onSubmit={handleSubmit(onUpdate)}
        noValidate
      >
        <InputLabel className={classes.label}>Kurssityypin nimi:</InputLabel>
        <TextField
          {...register('name.fi', {
            required: 'Pakollinen',
          })}
          inputProps={{
            className: classes.header,
          }}
          variant="outlined"
          defaultValue={courseType.name.fi}
        />
        <InputLabel className={classes.label}>
          Kurssityypin nimi (ENG):
        </InputLabel>
        <TextField
          {...register('name.en', {
            required: 'Pakollinen',
          })}
          inputProps={{
            className: classes.header,
          }}
          variant="outlined"
          defaultValue={courseType.name.en}
        />
        <InputLabel className={classes.label}>Kurssityypin kuvaus:</InputLabel>
        <TextField
          {...register('description.fi', {
            required: 'Pakollinen',
          })}
          inputProps={{
            className: classes.content,
          }}
          multiline
          variant="outlined"
          defaultValue={courseType.description.fi}
        />
        <InputLabel className={classes.label}>
          Kurssityypin kuvaus (ENG):
        </InputLabel>
        <TextField
          {...register('description.en', {
            required: 'Pakollinen',
          })}
          inputProps={{
            className: classes.content,
          }}
          multiline
          variant="outlined"
          defaultValue={courseType.description.en}
        />
        <InputLabel className={classes.label}>Kurssityypin ehdot:</InputLabel>
        <Controller
          name="extraInfo.fi"
          control={control}
          defaultValue={courseType.extraInfo ? courseType.extraInfo.fi : ''}
          render={({ field: { onChange } }) => (
            <Editor
              toolbar={{ options: ['inline', 'blockType'] }}
              editorClassName={classes.extraInfoEditor}
              editorState={extraInfoEditorStateFi}
              onEditorStateChange={(editorState) => {
                onChange(
                  draftToHtml(convertToRaw(editorState.getCurrentContent()))
                )
                setExtraInfoEditorStateFi(editorState)
              }}
            />
          )}
        />
        <InputLabel className={classes.label}>
          Kurssityypin ehdot (ENG):
        </InputLabel>
        <Controller
          name="extraInfo.en"
          control={control}
          defaultValue={courseType.extraInfo ? courseType.extraInfo.en : ''}
          render={({ field: { onChange } }) => (
            <Editor
              toolbar={{ options: ['inline', 'blockType'] }}
              editorClassName={classes.extraInfoEditor}
              editorState={extraInfoEditorStateEn}
              onEditorStateChange={(editorState) => {
                onChange(
                  draftToHtml(convertToRaw(editorState.getCurrentContent()))
                )
                setExtraInfoEditorStateEn(editorState)
              }}
            />
          )}
        />
        <div className={classes.flexRow}>
          <Button
            className={[classes.button, classes.blueText].join(' ')}
            type="submit"
            variant="contained"
            startIcon={<Save />}
          >
            Tallenna
          </Button>
          <Button
            className={[classes.button, classes.blueText].join(' ')}
            startIcon={<Cancel />}
            variant="contained"
            size="small"
            type="button"
            onClick={() => toggle()}
          >
            Peruuta
          </Button>
          <Button
            className={[classes.button, classes.redText].join(' ')}
            color="secondary"
            variant="contained"
            startIcon={<Delete />}
            size="small"
            onClick={() => onDelete(courseType)}
          >
            Poista kurssityyppi
          </Button>
        </div>
      </form>
    )
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {courseType.name.fi}
        <IconButton
          className={classes.button}
          size="small"
          onClick={() => toggle()}
        >
          <Edit />
        </IconButton>
      </Typography>
      <Typography className={classes.content}>
        {courseType.description.fi}
      </Typography>
    </div>
  )
}

export default CourseTypeDetails
