import React, { useContext, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import EditCourse from './EditCourse'
import courseUtils from '../../../utils'
import { StateContext } from '../../../state'

/**
 * Single course accordion
 */

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  courseTypeTitle: {
    marginRight: 20,
  },
}))

const Course = ({ course, archived }) => {
  const [expanded, setExpanded] = useState(false)
  const { state } = useContext(StateContext)

  const classes = useStyles()

  const editCourseProps = {
    course,
    expanded,
    setExpanded,
    archived,
  }

  const courseTypeTitle = state.courseTypes
    ? state.courseTypes[course.courseTypeId].name.fi
    : 'Kurssityyppi'

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={expanded ? <ExpandMoreIcon /> : <EditIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {archived ? (
          <Typography
            className={[classes.title, classes.courseTypeTitle].join(' ')}
          >
            {`${courseTypeTitle}:`}
          </Typography>
        ) : null}
        <Typography className={classes.title}>
          {courseUtils.parseCourseTitle(course)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && <EditCourse {...editCourseProps} />}
      </AccordionDetails>
    </Accordion>
  )
}

export default Course
