import React, { useState, useContext } from 'react'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import courseService from '../../../services/courses'

import { StateContext } from '../../../state'
import { updateCourse } from '../../../state/actions'
import utils from '../../../utils'
import useFirebase from '../../../hooks/firebase'

/**
 * Form dialog for admin to add participant inside Participants.js
 */

const useStyles = makeStyles((theme) => ({
  addButton: {
    margin: 8,
    backgroundColor: 'transparent',
    color: theme.palette.white,
    '&:hover': {
      color: theme.palette.success.main,
    },
    textTransform: 'none',
  },
  inlineRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(1),
    '& .MuiTextField-root': {
      margin: 0,
    },
  },
  addIcon: {
    fontSize: '28px !important',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: 600,
  },
}))

const AddParticipant = ({ course, setParticipants, archived }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const { dispatch } = useContext(StateContext)
  const firebase = useFirebase()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (data, event) => {
    event.preventDefault()
    const requestBody = {
      newParticipants: [
        {
          name: data.name,
          email: data.email,
          priceClass: data.priceRange,
          address: data.address,
          postalCode: data.postalCode,
          postOffice: data.postOffice,
        },
      ],
    }
    try {
      const sendAdminEmail = false
      const sendParticipantsEmail = false
      const response = await courseService.enroll(
        course.id,
        sendAdminEmail,
        sendParticipantsEmail,
        requestBody
      )
      if (response.status === 200) {
        dispatch(updateCourse(response.data))
        const config = await firebase.getTokenConfig()
        const participants = await courseService.getParticipants(
          course.id,
          config
        )
        setParticipants(participants)
      }
    } catch (e) {
      console.log(e.message)
    }
    setOpen(false)
  }

  const isFull = (c) => {
    return c.participants === c.maxParticipants
  }

  const containerStyle = isFull(course)
    ? { display: 'none' }
    : { display: 'inherit' }

  return (
    <div style={containerStyle}>
      <Button
        disabled={archived}
        onClick={handleClickOpen}
        color="secondary"
        size="small"
        className={classes.addButton}
        startIcon={<AddIcon className={classes.addIcon} />}
      >
        <Typography
          display="inline"
          align="left"
          className={classes.buttonLabel}
        >
          Lisää osallistuja
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogTitle id="form-dialog-title">
            Lisää osallistuja kurssille
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Syötä osallistujan tiedot.</DialogContentText>
            <TextField
              {...register('name', {
                required: 'Pakollinen',
              })}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Nimi"
              type="text"
              variant="outlined"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name.message}
            />
            <TextField
              {...register('email', {
                required: 'Pakollinen',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Sähköpostiosoite ei kelpaa',
                },
              })}
              required
              margin="dense"
              id="email"
              label="Sähköposti"
              type="email"
              variant="outlined"
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
            />
            <TextField
              {...register('address', {
                required: 'Pakollinen',
              })}
              required
              autoFocus
              margin="dense"
              id="address"
              label={t('form_address')}
              type="text"
              variant="outlined"
              fullWidth
              error={Boolean(errors.address)}
              helperText={errors.address && errors.address.message}
            />
            <div className={classes.inlineRoot}>
              <TextField
                {...register('postalCode', {
                  required: 'Pakollinen',
                })}
                required
                autoFocus
                margin="dense"
                id="postalCode"
                label={t('form_postal_code')}
                type="text"
                variant="outlined"
                fullWidth
                error={Boolean(errors.postalCode)}
                helperText={errors.postalCode && errors.postalCode.message}
              />
              <TextField
                {...register('postOffice', {
                  required: 'Pakollinen',
                })}
                required
                autoFocus
                margin="dense"
                id="postOffice"
                label={t('form_post_office')}
                type="text"
                variant="outlined"
                fullWidth
                error={Boolean(errors.postOffice)}
                helperText={errors.postOffice && errors.postOffice.message}
              />
            </div>
            {course.prices ? (
              <FormControl className={classes.formControl}>
                <InputLabel id="simple-select-outlined-label">
                  Hintaluokka
                </InputLabel>
                <Controller
                  control={control}
                  name="priceRange"
                  defaultValue={Object.keys(course.prices).sort()[0]}
                  render={(props) => (
                    <Select
                      inputRef={props.ref}
                      value={props.value}
                      labelId="simple-select-outlined-label"
                      id="simple-select-outlined"
                      onChange={props.onChange}
                      label="Hintaluokka"
                      required
                    >
                      {Object.keys(course.prices).map((priceRange) => (
                        <MenuItem
                          key={utils.generateRandomKey()}
                          value={priceRange}
                        >
                          {utils.translatePriceLabel(priceRange)} (
                          {course.prices[priceRange]} €)
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Peruuta
            </Button>
            <Button type="submit" color="primary">
              Lisää
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

export default AddParticipant
