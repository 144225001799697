import React, { createContext } from 'react'
// eslint-disable-next-line
import Firebase from '../components/auth/Firebase'

const FirebaseContext = createContext()

const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      {children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseContext, FirebaseProvider }
