import React, { useState } from 'react'
import {
  Button,
  TextField,
  makeStyles,
  IconButton,
  Typography,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useFormContext } from 'react-hook-form'

/**
 * Each priceType listed inside Prices/index
 */

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 70,
    backgroundColor: theme.palette.white,
    color: theme.palette.text.primary,
    padding: 5,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
  btn: {
    fontWeight: 600,
    fontSize: 12,
    width: 75,
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
  },
  editBtn: {
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.white,
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  editTableCell: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
  },
  buttonTableCell: {
    padding: 0,
  },
  cancelBtn: {
    fontWeight: 600,
    fontSize: 12,
    width: 75,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color: theme.palette.white,
    },
  },
  text: {
    fontSize: 16,
    fontWeight: 600,
  },
}))

const PriceTableRow = ({ label, prices, priceRange }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const { register } = useFormContext()

  const openForm = (event) => {
    event.preventDefault()
    setOpen(true)
  }

  if (open) {
    return (
      <>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Typography className={classes.text}>{label}</Typography>
          </TableCell>
          <TableCell className={classes.editTableCell}>
            <TextField
              {...register('morning')}
              className={classes.textField}
              type="number"
              defaultValue={prices.morning[priceRange]}
              id="morning"
              InputProps={{
                className: classes.inputElement,
              }}
            />
          </TableCell>
          <TableCell className={classes.editTableCell}>
            <TextField
              {...register('evening')}
              className={classes.textField}
              type="number"
              defaultValue={prices.evening[priceRange]}
              id="evening"
              InputProps={{
                className: classes.inputElement,
              }}
            />
            <input
              {...register('priceRange')}
              style={{ display: 'none' }}
              type="text"
              value={priceRange}
              readOnly
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell align="right" className={classes.tableCell}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              className={classes.cancelBtn}
              onClick={() => setOpen(!open)}
            >
              Peruuta
            </Button>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              size="small"
              type="submit"
              className={classes.btn}
            >
              Tallenna
            </Button>
          </TableCell>
        </TableRow>
      </>
    )
  }
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        <Typography className={classes.text}>{label}</Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography className={classes.text}>
          {prices.morning[priceRange]}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography className={classes.text}>
          {prices.evening[priceRange]}
        </Typography>
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        <IconButton
          className={classes.editBtn}
          type="button"
          onClick={(event) => openForm(event)}
        >
          <Edit />
        </IconButton>
        <input type="submit" style={{ display: 'none' }} />
      </TableCell>
    </TableRow>
  )
}

export default PriceTableRow
