const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COURSETYPES':
      return {
        ...state,
        courseTypes: {
          ...action.payload.reduce(
            (accumulator, courseType) => ({
              ...accumulator,
              [courseType.id]: courseType,
            }),
            {}
          ),
        },
      }
    case 'ADD_COURSETYPES':
      return {
        ...state,
        courseTypes: {
          ...state.courseTypes,
          ...action.payload.reduce(
            (accumulator, courseType) => ({
              ...accumulator,
              [courseType.id]: courseType,
            }),
            {}
          ),
        },
      }
    case 'DELETE_COURSETYPE':
      return {
        ...state,
        courseTypes: Object.keys(state.courseTypes)
          .filter((key) => key !== action.payload.id)
          .reduce((obj, key) => {
            obj[key] = state.courseTypes[key] //eslint-disable-line
            return obj
          }, {}),
      }
    case 'UPDATE_COURSETYPE':
      return {
        ...state,
        courseTypes: {
          ...state.courseTypes,
          [action.payload.id]: action.payload,
        },
      }
    case 'ADD_COURSES':
      return {
        ...state,
        courses: {
          ...state.courses,
          ...action.payload.reduce(
            (accumulator, course) => ({
              ...accumulator,
              [course.id]: course,
            }),
            {}
          ),
        },
      }
    case 'UPDATE_COURSE':
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.id]: action.payload,
        },
      }
    case 'DELETE_COURSE':
      return {
        ...state,
        courses: Object.keys(state.courses)
          .filter((key) => key !== action.payload.id)
          .reduce((obj, key) => {
            obj[key] = state.courses[key] //eslint-disable-line
            return obj
          }, {}),
      }
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.payload,
      }
    default:
      return state
  }
}

export default reducer
