import React from 'react'
import {
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core'
import { Euro } from '@material-ui/icons'

import utils from '../../../utils'
import TextWithIcon from '../../TextWithIcon'

/**
 * Fields to change the prices of the course
 */

const useStyles = makeStyles(() => ({
  textField: {
    width: 100,
    padding: 5,
  },
  inputElement: {
    paddingLeft: 10,
    fontWeight: 500,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 5,
  },
}))

const Prices = ({ prices, archived, handlePriceChange }) => {
  const classes = useStyles()

  return (
    <div>
      <Box mt={2} mb={1}>
        <TextWithIcon
          icon={<Euro />}
          text={
            <Typography display="inline" className={classes.title}>
              Hinta
            </Typography>
          }
        />
      </Box>
      {prices
        ? Object.keys(prices).map((p) => (
            <div key={p}>
              <InputLabel id={p}>
                <Typography className={classes.title}>
                  {utils.translatePriceLabel(p)}
                </Typography>
              </InputLabel>
              <TextField
                disabled={archived}
                className={classes.textField}
                onChange={(event) => handlePriceChange(p, event.target.value)}
                type="number"
                defaultValue={prices[p]}
                InputProps={{
                  className: classes.inputElement,
                }}
              />
            </div>
          ))
        : null}
    </div>
  )
}

export default Prices
