import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Grid,
  useMediaQuery,
  makeStyles,
  Link,
} from '@material-ui/core'
import { Person as PersonIcon } from '@material-ui/icons'
import { useForm, FormProvider } from 'react-hook-form'
import i18next from 'i18next'

import ParticipantInfo from './ParticipantInfo'

import courseService from '../../../services/courses'
import { StateContext } from '../../../state'
import { updateCourse } from '../../../state/actions'
import TermsDialog from './TermsDialog'
import TermsCheckbox from './TermsCheckbox'

/**
 * Enrollment form inside Course
 */

const useStyles = makeStyles((theme) => ({
  enrollmentForm: {
    width: '100%',
  },
  participantBtn: {
    margin: '5px 8px',
    backgroundColor: theme.palette.gray,
    color: theme.palette.success.contrastText,
    fontWeight: 600,
  },
  submitBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.darkGreen,
    },
    fontWeight: 'bold',
    fontSize: 12,
    padding: '7px 20px',
  },
  submitBtnContainer: {
    paddingLeft: '13px !important',
    textAlign: 'end',
  },
  xsSubmitBtnContainer: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  termsLink: {
    color: theme.palette.primary.main,
  },
}))

const EnrollmentForm = ({
  course,
  setFormOpen,
  setAccordionOpen,
  setError,
  setNotify,
}) => {
  const { state, dispatch } = useContext(StateContext)
  const { t } = useTranslation()
  const [participants, setParticipants] = useState(1)
  const [termsDialogOpen, setTermsDialogOpen] = useState(false)
  const [termsAnchorEl, setTermsAnchorEl] = useState(null)
  const participantInfos = []
  const methods = useForm()
  const { termsAccepted } = methods.watch()
  const locale = i18next.language
  const courseType = state.courseTypes[course.courseTypeId]

  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const onSubmit = async (data, event) => {
    event.preventDefault()
    if (!termsAccepted) return
    const participantObjects = []
    const requestBody = {}
    for (let i = 1; i < participants + 1; i += 1) {
      const obj = {
        name: data.name[`participant${i}`],
        email: data.email[`participant${i}`],
        address: data.address[`participant${i}`],
        postalCode: data.postalCode[`participant${i}`],
        postOffice: data.postOffice[`participant${i}`],
        priceClass: data.priceRange[`participant${i}`],
      }
      participantObjects.push(obj)
    }
    requestBody.newParticipants = participantObjects
    requestBody.language = locale
    if (data.message) {
      requestBody.message = data.message
    }

    try {
      let response = null
      const sendAdminEmail = true
      const sendParticipantsEmail = true
      response = await courseService.enroll(
        course.id,
        sendAdminEmail,
        sendParticipantsEmail,
        requestBody
      )

      if (response.status === 200) {
        setNotify(true)

        setTimeout(() => {
          setNotify(false)
          setFormOpen(false)
          setAccordionOpen(false)
        }, 2000)
        dispatch(updateCourse(response.data))
      }
    } catch (e) {
      console.log(e.message)
      setError(true)
      setNotify(true)
      setTimeout(() => {
        setNotify(false)
      }, 2000)
      setTimeout(() => {
        setError(false)
      }, 2500)
    }
  }

  const addParticipant = () => {
    if (participants < course.maxParticipants - course.participants) {
      setParticipants(participants + 1)
    }
  }

  const removeParticipant = () => {
    if (participants > 1) {
      setParticipants(participants - 1)
    }
  }

  for (let i = 0; i < participants; i += 1) {
    if (i === 0) {
      participantInfos.push(
        <ParticipantInfo
          course={course}
          key={i}
          withMsg
          participantNr={i + 1}
        />
      )
    } else {
      participantInfos.push(
        <ParticipantInfo course={course} key={i} participantNr={i + 1} />
      )
    }
  }

  const handleTermsDialogClose = () => {
    setTermsDialogOpen(false)
    setTermsAnchorEl(null)
  }

  const handleTermsDialogOpen = (event) => {
    event.preventDefault()
    setTermsDialogOpen(true)
    setTermsAnchorEl(event.currentTarget)
  }

  return (
    <FormProvider {...methods}>
      <form
        className={classes.enrollmentForm}
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        <Grid container spacing={1}>
          {participantInfos}
          <Grid item xs="auto">
            {participants < course.maxParticipants - course.participants ? (
              <Button
                className={classes.participantBtn}
                size="small"
                variant="contained"
                type="button"
                style={isSmallScreen ? { fontSize: 20, padding: '0px' } : null}
                onClick={addParticipant}
                color="secondary"
                startIcon={!isSmallScreen ? <PersonIcon /> : null}
                endIcon={
                  isSmallScreen ? <PersonIcon style={{ fontSize: 22 }} /> : null
                }
              >
                {!isSmallScreen ? t('btn_add_participant') : '+'}
              </Button>
            ) : null}

            {participants > 1 ? (
              <Button
                className={classes.participantBtn}
                size="small"
                variant="contained"
                type="button"
                style={isSmallScreen ? { fontSize: 20, padding: '0px' } : null}
                onClick={removeParticipant}
                color="secondary"
                startIcon={!isSmallScreen ? <PersonIcon /> : null}
                endIcon={
                  isSmallScreen ? <PersonIcon style={{ fontSize: 22 }} /> : null
                }
              >
                {!isSmallScreen ? t('btn_remove_participant') : '-'}
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            xs="auto"
            sm={12}
            className={
              isSmallScreen
                ? [
                    classes.submitBtnContainer,
                    classes.xsSubmitBtnContainer,
                  ].join(' ')
                : classes.submitBtnContainer
            }
          >
            <div style={{ marginBottom: 10 }}>
              <div style={{ display: 'inline-block' }}>
                <span>
                  {t('terms_accept_1')}
                  <a
                    href="#"
                    className={classes.termsLink}
                    onClick={handleTermsDialogOpen}
                  >
                    {t('terms_accept_2')}
                  </a>
                  :
                </span>
                <TermsCheckbox />
              </div>
              <Button
                className={
                  isSmallScreen
                    ? classes.submitBtn
                    : [classes.submitBtn, classes.marginRight].join(' ')
                }
                size={isSmallScreen ? 'small' : 'medium'}
                variant="contained"
                type="submit"
                disabled={!termsAccepted}
              >
                {t('btn_enroll')}
              </Button>
            </div>
            <TermsDialog
              extraInfo={courseType.extraInfo}
              open={termsDialogOpen}
              anchorEl={termsAnchorEl}
              handleClose={handleTermsDialogClose}
            />
            <div>
              <Link
                href={
                  locale === 'fi'
                    ? '/tietosuojaseloste.pdf'
                    : '/privacy-policy.pdf'
                }
                target="_blank"
              >
                {t('accordion_body_privacy_policy')}
              </Link>
            </div>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default EnrollmentForm
